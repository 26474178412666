<template>
    <div class="choose-section pt-100 pb-70 bg-off-white">
        <div class="container">
            <div class="section-title">
                <small>Why Choose Us</small>
                <h2>Why People Choose Lada For ML Services</h2>
                <p>Our purpose is to deliver excellence in service and execution Our purpose is to deliver excellence in service and Our purpose is to deliver excellence.</p>
            </div>
            <div class="process-content">
                <div class="row justify-content-center">
                    <div class="col-md-6 col-lg-4 pb-30">
                        <div class="process-item process-item-center border">
                            <div class="process-thumb">
                                <img src="../../assets/images/process/process-four.png" alt="shape">
                            </div>
                            <div class="process-text">
                                <h3>Easy Model Building</h3>
                                <p>Proin gravida nibh vel velit auctor aliquet Aenean sollicitudin. Proin gravida.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 pb-30">
                        <div class="process-item process-item-center border">
                            <div class="process-thumb">
                                <img src="../../assets/images/process/process-five.png" alt="shape">
                            </div>
                            <div class="process-text">
                                <h3>Robust ML Production Anywhere</h3>
                                <p>Proin gravida nibh vel velit auctor aliquet Aenean sollicitudin. Proin gravida.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 pb-30">
                        <div class="process-item process-item-center border">
                            <div class="process-thumb">
                                <img src="../../assets/images/process/process-six.png" alt="shape">
                            </div>
                            <div class="process-text">
                                <h3>Powerful Experimentation</h3>
                                <p>Proin gravida nibh vel velit auctor aliquet Aenean sollicitudin. Proin gravida.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhyChooseUs'
}
</script>