<template>
    <div class="blog-section pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 pb-30">
                    <div class="blog-details desk-pad-right-40">
                        <div>
                            <div class="forum-details mb-30">
                                <div class="blog-details-category">
                                    <img src="../../assets/images/blogs/blog-details.jpg" alt="blog">
                                    <div class="blog-category">Machine</div>
                                </div>
                                <h2>The Current State Of Artificial Intelligence Infographic</h2>
                                <p>lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulp theutate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non  mauris vitae erat consequat auctor eu in elit. Class thaptent taciti sociosqu ad litora torquent per. conubia nostra, per inceptos himenaeos. </p>
                                <ul>
                                    <li>Advance advisory team</li>
                                    <li>Advance quality experts</li>
                                    <li>There are many variations of passages.</li>
                                    <li>High-Quality results</li>
                                </ul>
                                <p>Lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulp theutate cursus a sit amet mauris.</p>

                                <div style="margin-bottom: 25px;"></div>

                                <blockquote>
                                    <p>“Lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulp theutate cursus a sit amet mauris.”</p>
                                    <span>― David Joe</span>
                                </blockquote>
                                <h3>The Rutrum Ullamcorper Mattis</h3>
                                <p>Lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulp theutate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non  mauris vitae erat consequat auctor eu in elit. Class thaptent taciti sociosqu ad litora torquent per. conubia nostra, per inceptos himenaeos.</p>
                                <h3>The Stand Lorem Ipsum Passage</h3>
                                <p>Lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulp theutate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non  mauris vitae erat consequat auctor eu in elit. Class thaptent taciti sociosqu ad litora torquent per. conubia nostra, per inceptos himenaeos.</p>

                                <div style="margin-bottom: 25px;"></div>
                                
                                <div class="row">
                                    <div class="col-12 col-sm-6">
                                        <img src="../../assets/images/blogs/blog-details-1.jpg" alt="blog">
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <img src="../../assets/images/blogs/blog-details-2.jpg" alt="blog">
                                    </div>
                                </div>
                                <p>Lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulp theutate cursus a sit amet mauris.</p>
                            </div>
                        </div>
                        <div class="post-review-area mb-30">
                            <div class="sub-section-title">
                                <h2 class="sub-section-title-heading">Comments (02)</h2>
                            </div>
                            <div class="post-review-box">
                                <div class="review-holder-item">
                                    <!-- main comment holder -->
                                    <div class="post-review-item post-review-item-main">
                                        <div class="post-review-thumb">
                                            <img src="../../assets/images/blogs/blog-author-1.jpg" alt="user">
                                        </div>
                                        <div class="post-review-content">
                                            <div class="post-review-content-header">
                                                <div class="post-review-header-item">
                                                    <h3>Jane Ronan</h3>
                                                    <p>3 weeks ago</p>
                                                </div>
                                                <div class="post-review-header-item">
                                                    <a href="#" class="post-review-btn">Reply</a>
                                                </div>
                                            </div>
                                            <p>Lorem ipsum dolor sit amet, consetetur sa dipscing elitr, sed diam nonumy ser irmodey tey mporep invidunt ut laboredi et dolore ma gna aliquyam.</p>
                                        </div>
                                    </div>
                                    <!-- reply of the comment. you can use this block as an item -->
                                    <div class="post-review-item post-review-item-reply">
                                        <div class="post-review-thumb">
                                            <img src="../../assets/images/blogs/blog-author-2.jpg" alt="review">
                                        </div>
                                        <div class="post-review-content">
                                            <div class="post-review-content-header">
                                                <div class="post-review-header-item">
                                                    <h3>Jennifer Andrew</h3>
                                                    <p>3 weeks ago</p>
                                                </div>
                                                <div class="post-review-header-item">
                                                    <a href="#" class="post-review-btn">Reply</a>
                                                </div>
                                            </div>
                                            <p>Lorem ipsum dolor sit amet, consetetur sa dipscing elitr, sed diam nonumy ser irmodey tey mporep invidunt ut laboredi et dolore ma gna aliquyam.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="review-holder-item">
                                    <!-- main comment holder -->
                                    <div class="post-review-item post-review-item-main">
                                        <div class="post-review-thumb">
                                            <img src="../../assets/images/blogs/blog-author-3.jpg" alt="review">
                                        </div>
                                        <div class="post-review-content">
                                            <div class="post-review-content-header">
                                                <div class="post-review-header-item">
                                                    <h3>Robert John</h3>
                                                    <p>3 weeks ago</p>
                                                </div>
                                                <div class="post-review-header-item">
                                                    <a href="#" class="post-review-btn">Reply</a>
                                                </div>
                                            </div>
                                            <p>Lorem ipsum dolor sit amet, consetetur sa dipscing elitr, sed diam nonumy ser irmodey tey mporep invidunt ut laboredi et dolore ma gna aliquyam.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="post-comment-area">
                            <div class="sub-section-title">
                                <h2 class="sub-section-title-heading">Leave A Comment</h2>
                            </div>
                            <form class="contact-form" id="contactForm">
                                <div class="form-group mb-20">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Your Name*" required />
                                </div>
                                <div class="form-group mb-20">
                                    <input type="text" name="email" id="email" class="form-control" placeholder="Your Email*" required/>
                                </div>
                                <div class="form-group mb-20">
                                    <textarea name="message" class="form-control" id="message" rows="6" placeholder="Your Comment*"></textarea>
                                </div>
                                <div class="input-checkbox mb-20">
                                    <input type="checkbox" id="contact1">
                                    <label for="contact1">Save my name and email in this browser for the next time I comment.</label>
                                </div>
                                <div class="form-button">
                                    <button class="btn main-btn full-width" type="submit">Send A Comment</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 pb-30">
                    <Sidebar />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from '../BlogRightSidebar/Sidebar'

export default {
    name: 'BlogDetails',
    components: {
        Sidebar
    }
}
</script>