<template>
    <div class="checkout-section pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-7 pb-30">
                    <div class="checkout-item desk-pad-right-30">
                        <div class="sub-section-title">
                            <h3 class="sub-section-title-heading">Billing Details</h3>
                        </div>
                        <div class="checkout-form">
                            <form>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group mb-20">
                                            <input type="text" name="email" class="form-control" placeholder="Email Address*">
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group mb-20">
                                            <input type="text" name="phone" class="form-control" placeholder="Phone Number*">
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="input-checkbox mb-20">
                                            <input type="checkbox" id="check1">
                                            <label for="check1">Get alert of product updates & offers</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-lg-6">
                                        <div class="form-group mb-20">
                                            <input type="email" name="name" class="form-control" placeholder="First Name*">
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-lg-6">
                                        <div class="form-group mb-20">
                                            <input type="email" name="name" class="form-control" placeholder="Last Name*">
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group mb-20">
                                            <select name="country" class="form-control">
                                                <option value="1">Your Country*</option>
                                                <option value="2">USA</option>
                                                <option value="3">UK</option>
                                                <option value="4">Germany</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group mb-20">
                                            <input type="email" name="company-name" class="form-control" placeholder="Company Name">
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group mb-20">
                                            <input type="email" name="address" class="form-control" placeholder="Address*">
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group mb-20">
                                            <select name="country" class="form-control">
                                                <option value="1">Town/City*</option>
                                                <option value="2">New York</option>
                                                <option value="3">Florida</option>
                                                <option value="4">Miami</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-lg-6">
                                        <div class="form-group mb-20">
                                            <input type="email" name="state-country" class="form-control" placeholder="State / Country*">
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-lg-6">
                                        <div class="form-group mb-20">
                                            <input type="email" name="postcode-zip" class="form-control" placeholder="Postcode / Zip*">
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="input-checkbox mb-20">
                                            <input type="checkbox" id="check2">
                                            <label for="check2" class="weight-600">Ship to a different address?</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group m-0">
                                            <textarea name="order" class="form-control" rows="12" placeholder="Order Notes*"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-5 pb-30">
                    <div class="checkout-item">
                        <div class="sub-section-title">
                            <h3 class="sub-section-title-heading">Your Orders</h3>
                        </div>
                        <div class="checkout-details mb-30">
                            <div class="cart-total-box">
                                <div class="cart-total-item">
                                    <h4 class="checkout-total-title">Product Name</h4>
                                    <p class="checkout-total-title">Total</p>
                                </div>
                                <div class="cart-total-item">
                                    <h4>Magnite Camera</h4>
                                    <p>$144.79</p>
                                </div>
                                <div class="cart-total-item">
                                    <h4>Spy Camera</h4>
                                    <p>$261.78</p>
                                </div>
                                <div class="cart-total-item">
                                    <h4>Security Camera</h4>
                                    <p>$217.18</p>
                                </div>
                                <div class="cart-total-item">
                                    <h4>Smart Camera</h4>
                                    <p>$316.77</p>
                                </div>
                                <div class="cart-total-item">
                                    <h4>CCTV Hidden</h4>
                                    <p>$115.14</p>
                                </div>
                                <div class="cart-total-item">
                                    <h4 class="checkout-total-title">Total</h4>
                                    <p class="checkout-total-title">$1055.66</p>
                                </div>
                            </div>
                        </div>
                        <div class="checkout-payment-area">
                            <div class="sub-section-title">
                                <h3 class="sub-section-title-heading">What's Payment Method</h3>
                            </div>
                            <div class="checkout-payment-form">
                                <form>
                                    <div class="input-checkbox mb-20">
                                        <input type="checkbox" id="check3">
                                        <label for="check3">Bank Transfer</label>
                                    </div>
                                    <div class="input-checkbox mb-20">
                                        <input type="checkbox" id="check4">
                                        <label for="check4">Paypal</label>
                                    </div>
                                    <div class="input-checkbox mb-20">
                                        <input type="checkbox" id="check5">
                                        <label for="check5">Visa</label>
                                    </div>
                                    <p>What ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore </p>
                                    <h3 class="cart-details-title mb-20">Card Details</h3>
                                    <div class="row">
                                        <div class="col-12 col-lg-6">
                                            <div class="form-group mb-20">
                                                <input type="text" name="name" class="form-control" required="" placeholder="Name On The Card*">
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-6">
                                            <div class="form-group mb-20">
                                                <input type="text" name="number" class="form-control" required="" placeholder="Card Number*">
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-6">
                                            <div class="form-group mb-20">
                                                <input type="text" name="date" class="form-control" required="" placeholder="Expire Date*">
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-6">
                                            <div class="form-group mb-20">
                                                <input type="text" name="cvn" class="form-control" required="" placeholder="CVN*">
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <button class="btn main-btn full-width" type="submit">
                                                Make Payment
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Checkout'
}
</script>