<template>
    <div class="service-section pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 pb-30">
                    <!-- <div class="sidebar-item sidebar-bg details-sidebar-search">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Search">
                            <button type="submit">
                                <i class="flaticon-search"></i>
                            </button>
                        </div>
                    </div> -->
                    <div class="sidebar-item sidebar-bg">
                        <h3>Services</h3>
                        <ul class="sidebar-list">
                            <li>
                                <router-link to="/machine-learning" >Machine Learning</router-link>
                            </li>
                            <li>
                                <router-link to="/computer-vision" >Computer Vision</router-link>
                            </li>
                            <li>
                                <router-link to="/data-analytics">Data Analytics</router-link>
                            </li>
                            <li>
                                <router-link to="/iot">IoT</router-link>
                            </li>
                            <li>
                                <router-link to="/mobile-web-development"  >Mobile App and Website Development</router-link>
                            </li>
                            <li>
                                <router-link to="/erp">Enterprise Resource Planning (ERP)</router-link>
                            </li>
                            <li>
                                <router-link to="/accounting-software">Accounting Software</router-link>
                            </li>
                            <li>
                                <router-link to="/pos" class="current">Point of Sale (POS) Solutions</router-link>
                            </li>
                           
                        </ul>
                    </div>
                    <!-- <div class="sidebar-item sidebar-bg bg-blue-dark">
                        <div class="sidebar-author">
                            <p>“Proin gravida nibh vel velit auctor aliquet nean sollicitudin.Proin gravida nibh vel velit auctor aliquet Aenean sollicitudin.Proin vel velit auctor aliquet Aenean sollicitudin.”</p>
                            <div class="author-info">
                                <img src="../../assets/images/author-1.jpg" alt="author">
                                <div class="author-info-details">
                                    <h3>Jonson Mandel</h3>
                                    <p>Los Angel</p>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="sidebar-item">
                        <h3>Recent Post</h3>
                        <div class="sidebar-recent-post">
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-4.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>Is Machine Learning Right Going On Good Way</h3>
                                        <ul class="post-entry">
                                            <li>January 25, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-5.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>The Current State Of Artificial Intelligence Infographic</h3>
                                        <ul class="post-entry">
                                            <li>January 24, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-6.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>Our Company As A Global Leader In Big Data</h3>
                                        <ul class="post-entry">
                                            <li>January 23, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="col-lg-8 pb-30">
                    <div class="forum-details desk-pad-right-40">
                        <img src="../../assets/images/services/pos.jpg" alt="service">
                        <h2>Point of Sale (POS) Solutions</h2>
                        <p>Our Point of Sale (POS) system is a comprehensive solution designed to streamline and enhance your retail operations. With a user-friendly interface and robust features, our POS system empowers businesses to efficiently manage sales, track inventory, process payments, and deliver exceptional customer experiences</p>
                        <!-- <h4>Native App Development</h4>
                        <p>We excel in developing native apps using platform-specific technologies such as Swift and Objective-C for iOS apps and Java or Kotlin for Android apps. Native apps offer the best performance, device integration, and access to the latest platform features.</p>
                       -->

                        <div class="row">
                        
                        
                            
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-yellow">
                                            <i class="flaticon-voice-recognition"></i>
                                        </div>
                                        <span>Sales Management</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>Our POS system enables businesses to process transactions quickly and accurately. With an intuitive interface, you can easily add products to the cart, apply discounts, and calculate taxes. Streamline your sales process with features like barcode scanning, integrated payment terminals, and customizable receipts.</p>

                                    
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-green">
                                            <i class="flaticon-machine-learning-1"></i>
                                        </div>
                                        <span>Inventory Management</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>Take control of your inventory with our POS system's powerful inventory management capabilities. Track stock levels, receive automated alerts for low inventory, and easily manage stock replenishment. Real-time inventory visibility ensures efficient stock management, reduces out-of-stock situations, and helps optimize ordering and stocking decisions.</p>

                                        <!-- <router-link to="/single-services" class="redirect-link">
                                            Discover More 
                                            <i class="icofont-rounded-right"></i>
                                        </router-link> -->
                                    </div>
                                </div>
                            </div>
                        
                        
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-blue">
                                            <i class="flaticon-machine-learning"></i>
                                        </div>
                                        <span>Customer Relationship Management (CRM)</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>Build and maintain strong customer relationships with our POS system's integrated CRM functionality. Capture customer information, track purchase history, and offer personalized promotions or loyalty programs. Gain valuable insights into customer preferences and behaviors to tailor your marketing strategies and enhance customer satisfaction.</p>

                                    
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-violet">
                                            <i class="flaticon-processor"></i>
                                        </div>
                                        <span>Reporting and Analytics</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>Make data-driven decisions with our POS system's reporting and analytics tools. Generate detailed sales reports, track performance metrics, and analyze trends to identify areas for improvement and optimize your business strategies. Accessible dashboards provide real-time insights into sales, inventory, and customer data.</p>

                                    
                                    </div>
                                </div>
                            </div>
                            
                        
                        </div>

                        <div class="row">
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-yellow">
                                            <i class="flaticon-machine-learning-1"></i>
                                        </div>
                                        <span>Payment Processing</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>Our POS system seamlessly integrates with various payment methods, including credit cards, debit cards, mobile wallets, and contactless payments. Accept payments securely and swiftly, providing customers with a smooth and convenient checkout experience. Integrated payment processing eliminates the need for separate terminals, simplifying transactions and reducing errors.</p>

                                    
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-blue">
                                            <i class="flaticon-machine-learning"></i>
                                        </div>
                                        <span>Multi-Store Management</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>For businesses with multiple locations, our POS system offers centralized management capabilities. Monitor and manage sales, inventory, and customer data across all stores from a single dashboard. This facilitates consistent pricing, streamlined operations, and improved decision-making across your retail chain.</p>

                                    
                                    </div>
                                </div>
                            </div>

                        
                        
                        </div>


                        <div class="row">
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-yellow">
                                            <i class="flaticon-machine-learning-1"></i>
                                        </div>
                                        <span>Scalability and Customization</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>Our POS system is designed to grow with your business. Whether you have a single store or a chain of locations, our system can scale to accommodate your expanding needs. We also offer customization options to tailor the POS system to your specific business requirements, ensuring a seamless fit for your operations.</p>

                                    
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-blue">
                                            <i class="flaticon-machine-learning"></i>
                                        </div>
                                        <span>User-Friendly Interface</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>Our POS system features an intuitive and user-friendly interface, reducing training time and increasing operational efficiency. Minimal clicks and easy navigation enable staff to quickly process transactions, access information, and provide exceptional customer service.</p>

                                    
                                    </div>
                                </div>
                            </div>

                        
                        
                        </div>

                        <div class="row">
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-yellow">
                                            <i class="flaticon-machine-learning-1"></i>
                                        </div>
                                        <span>Kitchen Display System (KDS) Integration</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>Our POS system seamlessly integrates with a Kitchen Display System (KDS), enabling efficient communication between the front-of-house and back-of-house operations. Orders placed at the POS terminal are instantly transmitted to the kitchen display, allowing kitchen staff to view and manage orders in real-time. This minimizes order errors, reduces ticket times, and improves overall kitchen efficiency.</p>

                                    
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-blue">
                                            <i class="flaticon-machine-learning"></i>
                                        </div>
                                        <span>Multiple Hardware Support:</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>Our versatile POS system supports a wide range of hardware devices, ensuring compatibility and flexibility for your business needs. </p>

                                    
                                    </div>
                                </div>
                            </div>

                        
                        
                        </div>


                        

                       <h2>Multiple Hardware Supports</h2>
                     
                       <h4>Barcode Scanners</h4>
                        <p>
                            Streamline the checkout process and reduce manual entry errors with barcode scanners. Quickly scan product barcodes for accurate and efficient sales processing.
                        </p>

                       <h4>Mobile Apps</h4>
                        <p>Expand your sales capabilities with our mobile app integration. Empower your staff to take orders, process payments, and provide customer service directly from mobile devices, enhancing flexibility and improving customer experiences.
                        </p>

                        <h4>
                            Weighing Scales</h4>
                        <p>
                            Our POS system seamlessly integrates with weighing scales, enabling businesses in industries such as grocery stores or delis to accurately weigh products at the point of sale. This ensures accurate pricing and streamlined checkout processes.
                    </p>

                    <h4>Receipt Printers</h4>
                        <p>Generate professional and customized receipts with our POS system's support for receipt printers. Print detailed transaction information, promotional offers, and customer messages to provide a polished and branded customer experience.
                        </p>

                        <h4>Payment Terminals</h4>
                        <p>Our POS system is compatible with various payment terminals, including credit card terminals, contactless payment devices, and mobile wallet solutions. Accept payments securely and conveniently, providing a seamless checkout experience for your customers.
                        </p>

                        <h4>Customer Display Units</h4>
                        <p>Enhance transparency and engage customers during the checkout process with customer display units. Show pricing, product information, promotions, and transaction details to keep customers informed and build trust.
                        </p>

                        <!-- <router-link to="/about-us" class="btn main-btn">Check It Out</router-link> -->
                    </div>
                </div>
   
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesDetails'
}
</script>