<template>
    <div class="service-section pt-100 pb-70">
        <div class="container">
            <div class="section-title section-title-lg">
                <small>Services</small>
                <h2>Our Purpose Is To Deliver Excellence <br> In Service And Execution</h2>
                <p>Our purpose is to deliver excellence and set the bar high, delivering excellence through unwavering dedication to exceptional service and flawless execution.</p>
                
            </div>
            <div class="row">
                <div class="col-md-6 col-lg-4 pb-30">
                    <div class="service-card">
                        <div class="service-card-thumb">
                            <i class="flaticon-automation"></i>
                        </div>
                        <div class="service-card-body">
                            <h3>Machine Learning</h3>
                            <p>Unlock the potential of data with our machine learning expertise, leveraging algorithms and models to extract meaningful insights and drive intelligent decision-making for your business.</p>
                            <router-link to="/machine-learning" class="redirect-link">
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 pb-30">
                    <div class="service-card">
                        <div class="service-card-thumb">
                            <i class="flaticon-deep-learning"></i>
                        </div>
                        <div class="service-card-body">
                            <h3>Computer Vision</h3>
                            <p>Our expert team combines state-of-the-art techniques with deep learning models to provide accurate and efficient solutions for various computer vision tasks, such as object detection, image recognition, image segmentation, and video analysis</p>
                            <router-link to="/computer-vision" class="redirect-link">
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 pb-30">
                    <div class="service-card">
                        <div class="service-card-thumb">
                            <i class="flaticon-predictive-chart"></i>
                        </div>
                        <div class="service-card-body">
                            <h3>Data Analytics</h3>
                            <p>Empower your business with data-driven insights through our robust analytics services, enabling you to uncover patterns, trends, and actionable intelligence that drive informed decision-making and business growth.</p>
                            <router-link to="/data-analytics" class="redirect-link">
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
               
                <div class="col-md-6 col-lg-4 pb-30">
                    <div class="service-card">
                        <div class="service-card-thumb">
                            <i class="flaticon-data-mining"></i>
                        </div>
                        <div class="service-card-body">
                            <h3>Mobile App and Website Development</h3>
                            <p>Harness the power of mobile and web technologies with our development services, creating intuitive, engaging, and scalable applications and websites that elevate your brand, reach your target audience, and drive user satisfaction.</p>
                            <router-link to="/mobile-web-development" class="redirect-link">
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 pb-30">
                    <div class="service-card">
                        <div class="service-card-thumb">
                            <i class="flaticon-machine-learning-2"></i>
                        </div>
                        <div class="service-card-body">
                            <h3>Enterprise Resource Planning (ERP)</h3>
                            <p>Streamline and integrate your business processes with our ERP solutions, enabling seamless management of resources, inventory, finances, and customer relationships for enhanced operational efficiency and business agility.</p>
                            <router-link to="/erp" class="redirect-link">
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 pb-30">
                    <div class="service-card">
                        <div class="service-card-thumb">
                            <i class="flaticon-diagram"></i>
                        </div>
                        <div class="service-card-body">
                            <h3>IoT</h3>
                            <p>Connect, monitor, and control your devices and systems with our IoT solutions, leveraging the power of real-time data and smart connectivity to optimize operations, improve efficiency, and create transformative experiences.</p>
                            <router-link to="/iot" class="redirect-link">
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
              
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>