<template>
    <div class="feature-section pt-min-100 pb-70">
        <div class="container">
            <div class="section-title section-title-lg">
                <h2>Any Sufficiently Advanced <br> Technology Is Indistinguishable From Magic</h2>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-lg-3 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-green">
                                    <i class="flaticon-machine-learning-1"></i>
                                </div>
                                <span>ML Technology</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>Unleash the power of ML technology to unlock the hidden potential of data.</p>
                                <router-link to="/machine-learning" class="redirect-link">
                                    Discover More 
                                    <i class="icofont-rounded-right"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-violet">
                                    <i class="flaticon-processor"></i>
                                </div>
                                <span>AI Technology</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>Experience the future with AI technology, where intelligent machines redefine possibilities.</p>
                                <router-link to="/computer-vision" class="redirect-link">
                                    Discover More 
                                    <i class="icofont-rounded-right"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-blue">
                                    <i class="flaticon-machine-learning"></i>
                                </div>
                                <span>Data Analytics</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>Uncover valuable insights and make data-driven decisions with the power of advanced data analytics.</p>
                                <router-link to="/data-analytics" class="redirect-link">
                                    Discover More 
                                    <i class="icofont-rounded-right"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-yellow">
                                    <i class="flaticon-voice-recognition"></i>
                                </div>
                                <span>IoT</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>Connect, control, and transform your world with the limitless possibilities of IoT technology.</p>
                                <router-link to="/iot" class="redirect-link">
                                    Discover More 
                                    <i class="icofont-rounded-right"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                  
                  
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Feature'
}
</script>