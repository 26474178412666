<template>
    <div class="testimonial-section pt-100 pb-100 bg-off-white">
        <div class="container">
            <div class="section-title">
                <small>Feedback</small>
                <h2>Our Feedback From Clients</h2>
            </div>
            <div class="row">
                <div class="col-md-6 col-lg-4 pb-30">
                    <div class="testimonial-card">
                        <div class="testimonial-card-body">
                            <div class="testimonial-card-inner-header">
                                <h3>Deltabel</h3>
                                <div class="testimonial-quote">
                                    <i class="icofont-quote-left"></i>
                                </div>
                            </div>
                            <p class="testimonial-para">Proin gravida nibh vel velit auctor aliquet Aenean sollicitudin. Proin gravida nibh vel velit auctor aliquet Aenean sollicitudin.</p>
                            <ul class="review-star-list">
                                <li class="starred"><i class="icofont-star"></i></li>
                                <li class="starred"><i class="icofont-star"></i></li>
                                <li class="starred"><i class="icofont-star"></i></li>
                                <li class="starred"><i class="icofont-star"></i></li>
                                <li class="starred"><i class="icofont-star"></i></li>
                            </ul>
                        </div>
                        <div class="testimonial-card-info">
                            <div class="testimonial-card-info-thumb">
                                <img src="../../assets/images/testimonial-1.jpg" alt="testimonial">
                            </div>
                            <div class="testimonial-card-info-text">
                                <h3 class="testimonial-name">Johnson Mandel</h3>
                                <p class="testimonial-address">Los Angel</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 pb-30">
                    <div class="testimonial-card">
                        <div class="testimonial-card-body">
                            <div class="testimonial-card-inner-header">
                                <h3>Pentham CX</h3>
                                <div class="testimonial-quote">
                                    <i class="icofont-quote-left"></i>
                                </div>
                            </div>
                            <p class="testimonial-para">Proin gravida nibh vel velit auctor aliquet Aenean sollicitudin. Proin gravida nibh vel velit auctor aliquet Aenean sollicitudin.</p>
                            <ul class="review-star-list">
                                <li class="starred"><i class="icofont-star"></i></li>
                                <li class="starred"><i class="icofont-star"></i></li>
                                <li class="starred"><i class="icofont-star"></i></li>
                                <li class="starred"><i class="icofont-star"></i></li>
                                <li class="starred"><i class="icofont-star"></i></li>
                            </ul>
                        </div>
                        <div class="testimonial-card-info">
                            <div class="testimonial-card-info-thumb">
                                <img src="../../assets/images/testimonial-2.jpg" alt="testimonial">
                            </div>
                            <div class="testimonial-card-info-text">
                                <h3 class="testimonial-name">Bentham Rober</h3>
                                <p class="testimonial-address">Bremen</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 pb-30 offset-lg-0 offset-md-3">
                    <div class="testimonial-card">
                        <div class="testimonial-card-body">
                            <div class="testimonial-card-inner-header">
                                <h3>Fantabulous</h3>
                                <div class="testimonial-quote">
                                    <i class="icofont-quote-left"></i>
                                </div>
                            </div>
                            <p class="testimonial-para">Proin gravida nibh vel velit auctor aliquet Aenean sollicitudin. Proin gravida nibh vel velit auctor aliquet Aenean sollicitudin.</p>
                            <ul class="review-star-list">
                                <li class="starred"><i class="icofont-star"></i></li>
                                <li class="starred"><i class="icofont-star"></i></li>
                                <li class="starred"><i class="icofont-star"></i></li>
                                <li class="starred"><i class="icofont-star"></i></li>
                                <li class="starred"><i class="icofont-star"></i></li>
                            </ul>
                        </div>
                        <div class="testimonial-card-info">
                            <div class="testimonial-card-info-thumb">
                                <img src="../../assets/images/testimonial-4.jpg" alt="testimonial">
                            </div>
                            <div class="testimonial-card-info-text">
                                <h3 class="testimonial-name">John Doe</h3>
                                <p class="testimonial-address">Los Angel</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Feedback'
}
</script>