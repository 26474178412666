<template>
    <div>
        <Navbar class="bg-white" />
        <PageTitle pageTitle="Data Analytics" />
        <ServicesDetails />
        <Newsletter />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import ServicesDetails from '../SingleService/ServicesDetailsDataAnalytics'
// import Newsletter from '../Common/Newsletter'
import Footer from '../Layout/Footer'

export default {
    name: 'Services',
    components: {
        Navbar,
        PageTitle,
        ServicesDetails,
        // Newsletter,
        Footer,
    }
}
</script>