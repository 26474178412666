<template>
    <div class="preloader">
        <div class="preloader-wrapper">
            <div class="preloader-content">
                <div class="blob-1"></div>
                <div class="blob-2"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Preloader'
}
</script>