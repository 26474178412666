<template>
    <div class="contact-info-section pt-100 pb-100">
        <div class="container">
            <div class="section-title section-title-lg">
                <small>Contact Information</small>
                <h2>Let's collaboratively create something truly extraordinary.</h2>
                <p>Partner with Infizen today and experience the transformative power of software
solutions that drive efficiency, productivity, and success. Together, let&#39;s embark on a

journey of digital transformation and achieve new heights in the ever-evolving
technology landscape.</p>
            </div>
            <!-- Contact-info -->
            <div class="contact-info mb-15">
                <div class="row">
                    <div class="col-md-6 col-lg-4 pb-30">
                        <div class="contact-info-item">
                            <div class="contact-info-header">
                                <div class="contact-info-header-icon">
                                    <i class="icofont-location-pin"></i>
                                </div>
                                <div class="contact-info-header-text">
                                    <h3>Location</h3>
                                    <p>Visit Our Office</p>
                                </div>
                            </div>
                            <div class="contact-info-body">
                                <p>Kwatta WEG 770</p>
                                <p>Wanica, Suriname</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 pb-30">
                        <div class="contact-info-item">
                            <div class="contact-info-header">
                                <div class="contact-info-header-icon">
                                    <i class="icofont-ui-call"></i>
                                </div>
                                <div class="contact-info-header-text">
                                    <h3>Make A Call</h3>
                                    <p>Let’s Talk with our Experts</p>
                                </div>
                            </div>
                            <div class="contact-info-body">
                                
                                <!-- <p> <img src="../../assets/images/india.png" style="height: 1.5rem;" alt="shape"> <a href="tel:091-999-801-0990">999-801-0990</a> / <a href="tel:091-999-845-5066">999-845-5066</a></p> -->
                                <p><img src="../../assets/images/suriname.jpg" style="height: 1.5rem;" alt="shape"> <a href="tel:+597 7171550">+597 7171550</a></p>
                                 <p><img src="../../assets/images/suriname.jpg" style="height: 1.5rem;" alt="shape"> <a href="tel:+597 536725">+597 536725</a></p>
                                <p>Mon - Fri: 09.00 to 17.00</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 pb-30 offset-lg-0 offset-md-3">
                        <div class="contact-info-item">
                            <div class="contact-info-header">
                                <div class="contact-info-header-icon">
                                    <i class="icofont-email"></i>
                                </div>
                                <div class="contact-info-header-text">
                                    <h3>Send An Email</h3>
                                    <p>Don’t Hesitate to Email</p>
                                </div>
                            </div>
                            <div class="contact-info-body">
                                <p>
                                    <a href="mailto:info@infizeninformatics.com">info@infizeninformatics.com </a>
                                </p>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Contact-info -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactInfo'
}
</script>