<template>
    <div class="service-section pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 pb-30">
                    <div class="forum-details desk-pad-right-40">
                        <img src="../../assets/images/cases/case-lg-1.jpg" alt="project">
                        <h2>Drone And Robot Training</h2>
                        <p>Lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulp theutate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non  mauris vitae erat consequat auctor eu in elit. Class thaptent taciti sociosqu ad litora torquent per. conubia nostra, per inceptos himenaeos.</p>
                        <ul>
                            <li>Advance advisory team</li>
                            <li>Advance quality experts</li>
                            <li>There are many variations of passages.</li>
                            <li>High-Quality results</li>
                        </ul>
                        <p>Lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulp theutate cursus a sit amet mauris.</p>
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <img src="../../assets/images/cases/case-lg-7.jpg" alt="project">
                            </div>
                            <div class="col-12 col-sm-6">
                                <img src="../../assets/images/cases/case-lg-3.jpg" alt="project">
                            </div>
                        </div>
                        <h3>Advanced Analytics Strategies</h3>
                        <p>Lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulp theutate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non  mauris vitae erat consequat auctor eu in elit. Class thaptent taciti sociosqu ad litora torquent per. conubia nostra, per inceptos himenaeos.</p>
                        <p>Lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulp theutate cursus a sit amet mauris.</p>
                    </div>
                </div>
                <div class="col-lg-4 pb-30">
                    <div class="sidebar-item sidebar-bg details-sidebar-search">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Search">
                            <button type="submit">
                                <i class="flaticon-search"></i>
                            </button>
                        </div>
                    </div>
                    <div class="sidebar-item sidebar-bg bg-blue-dark">
                        <div class="sidebar-author">
                            <p>“Proin gravida nibh vel velit auctor aliquet nean sollicitudin.Proin gravida nibh vel velit auctor aliquet Aenean sollicitudin.Proin vel velit auctor aliquet Aenean sollicitudin.”</p>
                            <div class="author-info">
                                <img src="../../assets/images/author-1.jpg" alt="author">
                                <div class="author-info-details">
                                    <h3>Jonson Mandel</h3>
                                    <p>Los Angel</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sidebar-item">
                        <h3>Recent Cases</h3>
                        <div class="sidebar-recent-post">
                            <div class="sidebar-recent-post-item">
                                <a href="single-case.html">
                                    <img src="../../assets/images/cases/case-lg-2.jpg" alt="project">
                                    <div class="recent-post-details">
                                        <h3>ML Tech Robot Training</h3>
                                        <ul class="post-entry">
                                            <li>January 25, 2021</li>
                                        </ul>
                                    </div>
                                </a>
                            </div>
                            <div class="sidebar-recent-post-item">
                                <a href="single-case.html">
                                    <img src="../../assets/images/cases/case-lg-4.jpg" alt="project">
                                    <div class="recent-post-details">
                                        <h3>Big Data Store</h3>
                                        <ul class="post-entry">
                                            <li>January 24, 2021</li>
                                        </ul>
                                    </div>
                                </a>
                            </div>
                            <div class="sidebar-recent-post-item">
                                <a href="single-case.html">
                                    <img src="../../assets/images/cases/case-lg-5.jpg" alt="project">
                                    <div class="recent-post-details">
                                        <h3>CX VirtualBox</h3>
                                        <ul class="post-entry">
                                            <li>January 23, 2021</li>
                                        </ul>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="sidebar-item sidebar-bg">
                        <h3>Categories</h3>
                        <ul class="sidebar-category">
                            <li>
                                <router-link to="/cases">
                                    Technology
                                    <span>(02)</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/cases">
                                    Machine Learning
                                    <span>(15)</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/cases">
                                    Development
                                    <span>(05)</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/cases">
                                    Data Mining
                                    <span>(07)</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/cases">
                                    IT
                                    <span>(02)</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SingleCasesStudy'
}
</script>