<template>
    <div class="service-section pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 pb-30">
                    <!-- <div class="sidebar-item sidebar-bg details-sidebar-search">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Search">
                            <button type="submit">
                                <i class="flaticon-search"></i>
                            </button>
                        </div>
                    </div> -->
                    <div class="sidebar-item sidebar-bg">
                        <h3>Services</h3>
                        <ul class="sidebar-list">
                            <li>
                                <router-link to="/machine-learning" >Machine Learning</router-link>
                            </li>
                            <li>
                                <router-link to="/computer-vision" >Computer Vision</router-link>
                            </li>
                            <li>
                                <router-link to="/data-analytics">Data Analytics</router-link>
                            </li>
                            <li>
                                <router-link to="/iot">IoT</router-link>
                            </li>
                            <li>
                                <router-link to="/mobile-web-development"  >Mobile App and Website Development</router-link>
                            </li>
                            <li>
                                <router-link to="/erp" class="current">Enterprise Resource Planning (ERP)</router-link>
                            </li>
                            <li>
                                <router-link to="/accounting-software">Accounting Software</router-link>
                            </li>
                            <li>
                                <router-link to="/pos">Point of Sale (POS) Solutions</router-link>
                            </li>
                           
                        </ul>
                    </div>
                    <!-- <div class="sidebar-item sidebar-bg bg-blue-dark">
                        <div class="sidebar-author">
                            <p>“Proin gravida nibh vel velit auctor aliquet nean sollicitudin.Proin gravida nibh vel velit auctor aliquet Aenean sollicitudin.Proin vel velit auctor aliquet Aenean sollicitudin.”</p>
                            <div class="author-info">
                                <img src="../../assets/images/author-1.jpg" alt="author">
                                <div class="author-info-details">
                                    <h3>Jonson Mandel</h3>
                                    <p>Los Angel</p>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="sidebar-item">
                        <h3>Recent Post</h3>
                        <div class="sidebar-recent-post">
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-4.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>Is Machine Learning Right Going On Good Way</h3>
                                        <ul class="post-entry">
                                            <li>January 25, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-5.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>The Current State Of Artificial Intelligence Infographic</h3>
                                        <ul class="post-entry">
                                            <li>January 24, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-6.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>Our Company As A Global Leader In Big Data</h3>
                                        <ul class="post-entry">
                                            <li>January 23, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="col-lg-8 pb-30">
                    <div class="forum-details desk-pad-right-40">
                        <img src="../../assets/images/services/erp.png" alt="service">
                        <h2>Enterprise Resource Planning (ERP)</h2>
                        <p>we specialize in providing comprehensive Enterprise Resource Planning (ERP) solutions that streamline business operations, enhance productivity, and drive growth. Our ERP solutions are designed to integrate various departments, functions, and processes within an organization, providing a unified and centralized platform for efficient management and decision-making.</p>
                        <!-- <h4>Native App Development</h4>
                        <p>We excel in developing native apps using platform-specific technologies such as Swift and Objective-C for iOS apps and Java or Kotlin for Android apps. Native apps offer the best performance, device integration, and access to the latest platform features.</p>
                       -->

                        <div class="row">
                        
                        
                            
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-yellow">
                                            <i class="flaticon-voice-recognition"></i>
                                        </div>
                                        <span>Financial Management</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>Streamlining accounting processes, managing financial transactions, and generating accurate financial reports</p>

                                    
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-green">
                                            <i class="flaticon-machine-learning-1"></i>
                                        </div>
                                        <span>Supply Chain Management</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>Optimizing inventory management, procurement, and logistics, ensuring efficient order fulfillment and timely delivery.</p>

                                        <!-- <router-link to="/single-services" class="redirect-link">
                                            Discover More 
                                            <i class="icofont-rounded-right"></i>
                                        </router-link> -->
                                    </div>
                                </div>
                            </div>
                        
                        
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-blue">
                                            <i class="flaticon-machine-learning"></i>
                                        </div>
                                        <span>Human Resources Management</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>Managing employee data, payroll, attendance, performance evaluations, and training programs.</p>

                                    
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-violet">
                                            <i class="flaticon-processor"></i>
                                        </div>
                                        <span>Sales and Customer Management</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>Tracking sales activities, managing leads and opportunities, and providing comprehensive customer service.</p>

                                    
                                    </div>
                                </div>
                            </div>
                            
                        
                        </div>

                        <div class="row">
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-yellow">
                                            <i class="flaticon-machine-learning-1"></i>
                                        </div>
                                        <span>Manufacturing and Production Control</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>Planning and scheduling production, tracking work orders, managing bills of materials, and optimizing resource allocation.</p>

                                    
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-blue">
                                            <i class="flaticon-machine-learning"></i>
                                        </div>
                                        <span>Business Intelligence and Analytics</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>Leveraging data analytics tools to extract insights, generate reports, and support informed decision-making.</p>

                                    
                                    </div>
                                </div>
                            </div>

                        
                        
                        </div>


                        

                       <h2> Benefits of ERP</h2>
                       <p>Implementing our ERP solutions offers numerous benefits for businesses, including</p>
                       <h4>Process Optimization</h4>
                        <p>
                            Our ERP systems enable businesses to automate and optimize key processes across departments, reducing manual effort and improving operational efficiency. From inventory management to order processing, financial management to human resources, our ERP solutions streamline workflows and enhance productivity.
                        </p>

                       <h4>Data Integration and Centralization</h4>
                        <p>ERP brings together data from different departments and functions, providing a holistic view of the organization. This centralization allows for better data integrity, improved collaboration, and faster access to accurate information. Decision-makers can rely on real-time data to make informed and strategic decisions.
                        </p>

                        <h4>
                            Improved Visibility and Reporting</h4>
                        <p>
                            With ERP, businesses gain enhanced visibility into their operations. Customizable dashboards and reporting tools enable stakeholders to monitor key performance indicators (KPIs), track progress, and identify bottlenecks. This empowers management to make data-driven decisions, optimize resources, and respond promptly to changing business needs.
                    </p>

                    <h4>Enhanced Customer Relationship Management</h4>
                        <p>ERP systems often include Customer Relationship Management (CRM) modules, enabling businesses to manage customer interactions, track sales pipelines, and provide personalized service. This fosters stronger customer relationships, improves customer satisfaction, and drives customer retention and loyalty.
                        </p>

                        <h4>Scalability and Flexibility</h4>
                        <p>Our ERP solutions are designed to accommodate the evolving needs of growing businesses. They offer scalability and flexibility, allowing organizations to add new modules, users, and functionalities as required. This ensures that the ERP system can adapt to changes in the business environment and support future growth.
                        </p>
                    </div>
                </div>
   
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesDetails'
}
</script>