<template>
    <div class="service-section pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 pb-30">
                    <!-- <div class="sidebar-item sidebar-bg details-sidebar-search">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Search">
                            <button type="submit">
                                <i class="flaticon-search"></i>
                            </button>
                        </div>
                    </div> -->
                    <div class="sidebar-item sidebar-bg">
                        <h3>Services</h3>
                        <ul class="sidebar-list">
                            <li>
                                <router-link to="/machine-learning" >Machine Learning</router-link>
                            </li>
                            <li>
                                <router-link to="/computer-vision" >Computer Vision</router-link>
                            </li>
                            <li>
                                <router-link to="/data-analytics">Data Analytics</router-link>
                            </li>
                            <li>
                                <router-link to="/iot">IoT</router-link>
                            </li>
                            <li>
                                <router-link to="/mobile-web-development"  class="current">Mobile App and Website Development</router-link>
                            </li>
                            <li>
                                <router-link to="/erp">Enterprise Resource Planning (ERP)</router-link>
                            </li>
                            <li>
                                <router-link to="/accounting-software">Accounting Software</router-link>
                            </li>
                            <li>
                                <router-link to="/pos">Point of Sale (POS) Solutions</router-link>
                            </li>
                           
                        </ul>
                    </div>
                    <!-- <div class="sidebar-item sidebar-bg bg-blue-dark">
                        <div class="sidebar-author">
                            <p>“Proin gravida nibh vel velit auctor aliquet nean sollicitudin.Proin gravida nibh vel velit auctor aliquet Aenean sollicitudin.Proin vel velit auctor aliquet Aenean sollicitudin.”</p>
                            <div class="author-info">
                                <img src="../../assets/images/author-1.jpg" alt="author">
                                <div class="author-info-details">
                                    <h3>Jonson Mandel</h3>
                                    <p>Los Angel</p>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="sidebar-item">
                        <h3>Recent Post</h3>
                        <div class="sidebar-recent-post">
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-4.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>Is Machine Learning Right Going On Good Way</h3>
                                        <ul class="post-entry">
                                            <li>January 25, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-5.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>The Current State Of Artificial Intelligence Infographic</h3>
                                        <ul class="post-entry">
                                            <li>January 24, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-6.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>Our Company As A Global Leader In Big Data</h3>
                                        <ul class="post-entry">
                                            <li>January 23, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="col-lg-8 pb-30">
                    <div class="forum-details desk-pad-right-40">
                        <img src="../../assets/images/services/mobileapp-website.jpg" alt="service">
                        <h2>Mobile App Development</h2>
                        <p>Our mobile app development services are designed to transform your ideas into innovative and user-friendly mobile applications. Whether you need an iOS, Android, or cross-platform app, our skilled team of developers uses the latest technologies to deliver high-quality solutions. We specialize in creating intuitive interfaces, seamless user experiences, and robust functionalities that cater to your specific business requirements. From concept to deployment, we ensure that your app is optimized for performance, security, and scalability.</p>
                        <!-- <h4>Native App Development</h4>
                        <p>We excel in developing native apps using platform-specific technologies such as Swift and Objective-C for iOS apps and Java or Kotlin for Android apps. Native apps offer the best performance, device integration, and access to the latest platform features.</p>
                       -->

                        <div class="row">
                        
                        
                            
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-yellow">
                                            <i class="flaticon-voice-recognition"></i>
                                        </div>
                                        <span>Native App Development</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>We excel in developing native apps using platform-specific technologies such as Swift and Objective-C for iOS apps and Java or Kotlin for Android apps. Native apps offer the best performance, device integration, and access to the latest platform features.</p>

                                    
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-green">
                                            <i class="flaticon-machine-learning-1"></i>
                                        </div>
                                        <span>Cross-Platform App Development</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>Our team is proficient in cross-platform frameworks like React Native and Flutter. By leveraging these technologies, we build apps that work seamlessly across multiple platforms, reducing development time and costs while maintaining a native-like user experience.</p>

                                        <!-- <router-link to="/single-services" class="redirect-link">
                                            Discover More 
                                            <i class="icofont-rounded-right"></i>
                                        </router-link> -->
                                    </div>
                                </div>
                            </div>
                        
                        
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-blue">
                                            <i class="flaticon-machine-learning"></i>
                                        </div>
                                        <span>Backend Development</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>To support the functionality and data management of your mobile app, we employ backend development technologies such as Node.js, Python, or Ruby on Rails. This enables us to create robust APIs, handle data synchronization, implement user authentication, and integrate with external services.</p>

                                    
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-violet">
                                            <i class="flaticon-processor"></i>
                                        </div>
                                        <span>Cloud Integration</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>We integrate cloud services like AWS, Google Cloud, or Azure to ensure scalability, data storage, and seamless synchronization across devices. Cloud integration also enhances app performance, security, and accessibility.</p>

                                    
                                    </div>
                                </div>
                            </div>
                            
                        
                        </div>

                        <div class="row">
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-yellow">
                                            <i class="flaticon-machine-learning-1"></i>
                                        </div>
                                        <span>App Analytics and Tracking</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>We implement app analytics and tracking solutions like Google Analytics or Firebase Analytics to provide valuable insights into user behavior, app usage, and performance metrics. This data-driven approach helps you make data-informed decisions and optimize your app for better user engagement.</p>

                                    
                                    </div>
                                </div>
                            </div>

                        
                        
                        </div>


                        <h2>Website Development</h2>
                        <p>Our website development services focus on creating visually appealing, responsive, and user-centric websites that enhance your online presence. We combine cutting-edge technologies with creative designs to deliver websites that are not only visually stunning but also functionally robust. We tailor our solutions to meet your specific needs, whether it's an informational website, e-commerce platform, or a complex web application.</p>
                        <!-- <h4>Native App Development</h4>
                        <p>We excel in developing native apps using platform-specific technologies such as Swift and Objective-C for iOS apps and Java or Kotlin for Android apps. Native apps offer the best performance, device integration, and access to the latest platform features.</p>
                       -->

                        <div class="row">
                        
                        
                            
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-yellow">
                                            <i class="flaticon-voice-recognition"></i>
                                        </div>
                                        <span>Front-end Development</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>We use HTML5, CSS3, and JavaScript frameworks like React.js or AngularJS to build engaging and interactive user interfaces. Our front-end development expertise ensures cross-browser compatibility, responsive design, and optimal performance across devices.</p>

                                    
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-green">
                                            <i class="flaticon-machine-learning-1"></i>
                                        </div>
                                        <span>Back-end Development</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>For server-side development, we employ technologies like Node.js, PHP, Python, or Ruby on Rails. Our back-end developers create secure and scalable architectures, implement database management systems, and ensure efficient data processing and server-side functionality.</p>

                                        <!-- <router-link to="/single-services" class="redirect-link">
                                            Discover More 
                                            <i class="icofont-rounded-right"></i>
                                        </router-link> -->
                                    </div>
                                </div>
                            </div>
                        
                        
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-blue">
                                            <i class="flaticon-machine-learning"></i>
                                        </div>
                                        <span>Content Management Systems (CMS)</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>We work with popular CMS platforms like WordPress, Drupal, or Joomla, enabling you to easily manage and update your website content. CMS integration provides flexibility and empowers you to maintain your website without technical expertise.</p>

                                    
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-violet">
                                            <i class="flaticon-processor"></i>
                                        </div>
                                        <span>E-commerce Development</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>If you require an online store, we specialize in e-commerce development using platforms such as WooCommerce, Shopify, or Magento. We build secure and user-friendly e-commerce websites with features like product catalogs, shopping carts, payment gateways, and order management systems.</p>

                                    
                                    </div>
                                </div>
                            </div>
                            
                        
                        </div>


                     
                        
                        <div class="row">
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-yellow">
                                            <i class="flaticon-machine-learning-1"></i>
                                        </div>
                                        <span>Integration and APIs</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>We integrate third-party APIs and services to enhance website functionality and provide seamless user experiences. This can include social media integration, payment gateways, mapping services, or custom API integrations tailored to your specific requirements.</p>

                                    
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-yellow">
                                            <i class="flaticon-machine-learning-1"></i>
                                        </div>
                                        <span>App Analytics and Tracking</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>We implement app analytics and tracking solutions like Google Analytics or Firebase Analytics to provide valuable insights into user behavior, app usage, and performance metrics. This data-driven approach helps you make data-informed decisions and optimize your app for better user engagement.</p>

                                    
                                    </div>
                                </div>
                            </div>

                        
                        
                        </div>
           

                       <h4> Agile Development Process:</h4>
                        <p>
                            We follow an agile development process that emphasizes close collaboration, flexibility, and iterative development. Our approach ensures that we remain responsive to your feedback and evolving requirements throughout the project lifecycle. We prioritize effective communication, transparent project management, and timely delivery to ensure a smooth and successful development experience.
                        </p>

                       <h4> Quality Assurance and Testing:</h4>
                        <p>At our company, we place a strong emphasis on quality assurance and testing to ensure that your mobile app or website performs flawlessly. Our dedicated QA team conducts rigorous testing across multiple devices, platforms, and browsers to identify and address any issues or bugs. We strive for excellence and take pride in delivering high-quality solutions that exceed your expectations.
                        </p>

                        <h4>
                        Support and Maintenance:</h4>
                        <p>
                        We provide ongoing support and maintenance services to ensure that your mobile app or website continues to operate smoothly even after deployment. We monitor performance, address any potential issues, and provide timely updates and enhancements to keep your digital assets up to date and secure.
                    </p>
                    </div>
                </div>
   
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesDetails'
}
</script>