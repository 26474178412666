<template>
    <div class="demo-product-section bg-off-white pt-100 pb-70">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 pb-30">
                    <div class="about-section-item about-item-image text-center desk-pad-right-70">
                        <img src="../../assets/images/demo-1.png" alt="shape">
                    </div>
                </div>
                <div class="col-lg-6 pb-30">
                    <div class="about-section-item about-item-details">
                        <div class="section-title section-title-left text-start mb-0">
                            <h2>Automatic Face Detection</h2>
                            <p>Lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulp theutate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non  mauris vitae erat consequat auctor eu in elit. Class aptent taciti sociosqu ad litora torquent per.</p>
                            <router-link to="/about-us" class="btn main-btn">Check It Out</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="demo-product-section bg-off-white pt-100 pb-70">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 pb-30">
                    <div class="about-section-item about-item-image text-center desk-pad-right-70">
                        <img src="../../assets/images/demo-2.png" alt="shape">
                    </div>
                </div>
                <div class="col-lg-6 pb-30">
                    <div class="about-section-item about-item-details">
                        <div class="section-title section-title-left text-start mb-0">
                            <h2>Image Processing</h2>
                            <p>Lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulp theutate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non  mauris vitae erat consequat auctor eu in elit. Class aptent taciti sociosqu ad litora torquent per.</p>
                            <router-link to="/about-us" class="btn main-btn">Check It Out</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DemoProduct'
}
</script>