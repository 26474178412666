<template>
    <div class="service-section pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 pb-30">
                    <!-- <div class="sidebar-item sidebar-bg details-sidebar-search">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Search">
                            <button type="submit">
                                <i class="flaticon-search"></i>
                            </button>
                        </div>
                    </div> -->
                    <div class="sidebar-item sidebar-bg">
                        <h3>Services</h3>
                        <ul class="sidebar-list">
                            <li>
                                <router-link to="/machine-learning" class="current">Machine Learning</router-link>
                            </li>
                            <li>
                                <router-link to="/computer-vision">Computer Vision</router-link>
                            </li>
                            <li>
                                <router-link to="/data-analytics">Data Analytics</router-link>
                            </li>
                            <li>
                                <router-link to="/iot">IoT</router-link>
                            </li>
                            <li>
                                <router-link to="/mobile-web-development">Mobile App and Website Development</router-link>
                            </li>
                            <li>
                                <router-link to="/erp">Enterprise Resource Planning (ERP)</router-link>
                            </li>
                            <li>
                                <router-link to="/accounting-software">Accounting Software</router-link>
                            </li>
                            <li>
                                <router-link to="/pos">Point of Sale (POS) Solutions</router-link>
                            </li>
                           
                        </ul>
                    </div>
                    <!-- <div class="sidebar-item sidebar-bg bg-blue-dark">
                        <div class="sidebar-author">
                            <p>“Proin gravida nibh vel velit auctor aliquet nean sollicitudin.Proin gravida nibh vel velit auctor aliquet Aenean sollicitudin.Proin vel velit auctor aliquet Aenean sollicitudin.”</p>
                            <div class="author-info">
                                <img src="../../assets/images/author-1.jpg" alt="author">
                                <div class="author-info-details">
                                    <h3>Jonson Mandel</h3>
                                    <p>Los Angel</p>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="sidebar-item">
                        <h3>Recent Post</h3>
                        <div class="sidebar-recent-post">
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-4.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>Is Machine Learning Right Going On Good Way</h3>
                                        <ul class="post-entry">
                                            <li>January 25, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-5.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>The Current State Of Artificial Intelligence Infographic</h3>
                                        <ul class="post-entry">
                                            <li>January 24, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-6.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>Our Company As A Global Leader In Big Data</h3>
                                        <ul class="post-entry">
                                            <li>January 23, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="col-lg-8 pb-30">
                    <div class="forum-details desk-pad-right-40">
                        <img src="../../assets/images/services/service-details-1.jpg" alt="service">
                        <h2>Machine Learning</h2>
                        <p>Harness the power of  ML technologies to revolutionize your business processes. Our expert team specializes in developing intelligent systems that can automate tasks, analyze data, and provide valuable insights to optimize your operations and drive innovation.</p>
                        <p>As a machine learning solutions provider, we enable rapid decision making, increased productivity, business process automation, and faster anomaly detection by using a myriad of techniques such as mathematical optimization, pattern recognition, computational intelligence and more.</p>
                       

                <div class="row">
                  
                   
                    
                    <div class="col-md-12 col-lg-6 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-yellow">
                                    <i class="flaticon-voice-recognition"></i>
                                </div>
                                <span>Supervised Learning</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>This method relies on the training dataset to learn functions from inputs and meet the desired output values through methods like regression, classification & prediction. Multiple iterations ensure efficient mapping and accurate predictions of business outcomes. Yield superior results from our guided learning models, from spam filtering to improved products, meaningful insights, quick decision-making, risk analysis, and more.</p>

                              
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-green">
                                    <i class="flaticon-machine-learning-1"></i>
                                </div>
                                <span>Unsupervised Learning</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>Develop future-ready applications across different business cases that learn and adapt over time with usage by building models that explore, assess and process unstructured data and find some structure and insights within. Uncover hidden relationships, classify customer needs, target marketing campaigns, enable text understanding, and much more to reshape the operations of your business world.</p>

                                <!-- <router-link to="/single-services" class="redirect-link">
                                    Discover More 
                                    <i class="icofont-rounded-right"></i>
                                </router-link> -->
                            </div>
                        </div>
                    </div>
                  
                  
                </div>
                <div class="row">
                    <div class="col-md-12 col-lg-6 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-blue">
                                    <i class="flaticon-machine-learning"></i>
                                </div>
                                <span>Deep Learning</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>Deep learning is the bedrock of high-level synthetic intelligence. While machine learning focuses on available data and known properties, deep learning uses a layered approach of artificial neural networks to discover scalable solutions through predictive and prescriptive analysis. The model essentially learns, interacts, and performs complex tasks without human intervention.</p>

                               
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-6 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-violet">
                                    <i class="flaticon-processor"></i>
                                </div>
                                <span>Reinforcement Learning</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>The reinforcement learning model focuses on determining actions that can optimize performance and yield the best reward over time. This technique uses experimentative training to figure out how to achieve optimal results in a given environment and stay ahead of disruption. Its dynamic applications span the fields of navigation, robotics, gaming, telecommunications, and more.</p>

                                <!-- <router-link to="/single-services" class="redirect-link">
                                    Discover More 
                                    <i class="icofont-rounded-right"></i>
                                </router-link> -->
                            </div>
                        </div>
                    </div>
                    
                  
                </div>
           

                        <h2>Our Process</h2>
                        <h4>1. Defining the Problem</h4>
                        <p> We collaborate closely with our clients to understand their objectives and challenges. We conduct in-depth discussions to identify the specific problem we aim to solve using machine learning. This involves understanding the desired outcomes, available data sources, and any constraints or requirements unique to the project.</p>

                        <h4>2. Data Collection and Preparation</h4>
                        <p> With a well-defined problem, we proceed to collect the relevant data from various sources. This may involve leveraging our clients' existing databases, accessing external datasets, or setting up data acquisition processes. Our data experts then carefully preprocess the data, performing tasks like data cleaning, feature extraction, normalization, and addressing missing values. We ensure that the data is structured in a way that enables efficient analysis and modeling.</p>

                        <h4>3. Feature Engineering</h4>
                        <p> Our experienced data scientists and domain experts collaborate to engineer features that capture the underlying patterns and relationships within the data. We leverage our industry knowledge to extract meaningful insights and create relevant features that maximize the predictive power of our models. Techniques such as dimensionality reduction and feature scaling are also employed to enhance model performance.</p>

                        <h4>4. Model Selection</h4>
                        <p> Our team selects the most appropriate machine learning model based on the problem at hand, data characteristics, and performance requirements. We consider a wide range of models, including decision trees, support vector machines, neural networks, and ensemble methods. Our choice is driven by extensive experimentation and a deep understanding of the strengths and limitations of different algorithms.</p>

                        <h4>5. Training the Model</h4>
                        <p> Once the model is selected, we train it using the preprocessed data. Our data scientists leverage advanced optimization algorithms, such as gradient descent or maximum likelihood estimation, to ensure the model learns the underlying patterns and optimizes its predictive capabilities. We iterate on the training process, refining the model's parameters until we achieve the desired level of performance.</p>

                        <h4>6. Model Evaluation</h4>
                        <p> We employ rigorous evaluation techniques to assess the performance of our trained models. We utilize appropriate evaluation metrics, tailored to the specific problem, to measure accuracy, precision, recall, or other relevant metrics. We implement cross-validation strategies, such as k-fold cross-validation, to obtain reliable estimates of model performance and to ensure its robustness across different data subsets.</p>

                        <h4>7. Model Deployment</h4>
                        <p>We prioritize seamless integration and deployment of our machine learning models. We ensure scalability and real-time predictions by leveraging cloud platforms or deploying models within our clients' infrastructure. Our deployment process involves serializing the trained model, building user-friendly application interfaces, and implementing robust monitoring mechanisms to detect any performance drift and ensure ongoing model effectiveness.</p>

                        
                    </div>
                </div>
   
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesDetails'
}
</script>