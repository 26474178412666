<template>
    <div class="service-section pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 pb-30">
                    <!-- <div class="sidebar-item sidebar-bg details-sidebar-search">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Search">
                            <button type="submit">
                                <i class="flaticon-search"></i>
                            </button>
                        </div>
                    </div> -->
                    <div class="sidebar-item sidebar-bg">
                        <h3>Services</h3>
                        <ul class="sidebar-list">
                            <li>
                                <router-link to="/machine-learning" >Machine Learning</router-link>
                            </li>
                            <li>
                                <router-link to="/computer-vision" class="current">Computer Vision</router-link>
                            </li>
                            <li>
                                <router-link to="/data-analytics">Data Analytics</router-link>
                            </li>
                            <li>
                                <router-link to="/iot">IoT</router-link>
                            </li>
                            <li>
                                <router-link to="/mobile-web-development">Mobile App and Website Development</router-link>
                            </li>
                            <li>
                                <router-link to="/erp">Enterprise Resource Planning (ERP)</router-link>
                            </li>
                            <li>
                                <router-link to="/accounting-software">Accounting Software</router-link>
                            </li>
                            <li>
                                <router-link to="/pos">Point of Sale (POS) Solutions</router-link>
                            </li>
                           
                        </ul>
                    </div>
                    <!-- <div class="sidebar-item sidebar-bg bg-blue-dark">
                        <div class="sidebar-author">
                            <p>“Proin gravida nibh vel velit auctor aliquet nean sollicitudin.Proin gravida nibh vel velit auctor aliquet Aenean sollicitudin.Proin vel velit auctor aliquet Aenean sollicitudin.”</p>
                            <div class="author-info">
                                <img src="../../assets/images/author-1.jpg" alt="author">
                                <div class="author-info-details">
                                    <h3>Jonson Mandel</h3>
                                    <p>Los Angel</p>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="sidebar-item">
                        <h3>Recent Post</h3>
                        <div class="sidebar-recent-post">
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-4.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>Is Machine Learning Right Going On Good Way</h3>
                                        <ul class="post-entry">
                                            <li>January 25, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-5.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>The Current State Of Artificial Intelligence Infographic</h3>
                                        <ul class="post-entry">
                                            <li>January 24, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-6.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>Our Company As A Global Leader In Big Data</h3>
                                        <ul class="post-entry">
                                            <li>January 23, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="col-lg-8 pb-30">
                    <div class="forum-details desk-pad-right-40">
                        <img src="../../assets/images/services/cv.jpg" alt="service">
                        <h2>Computer Vision</h2>
                        <p>We offer cutting-edge computer vision services that leverage advanced algorithms and artificial intelligence to extract valuable insights from visual data. Our expert team combines state-of-the-art techniques with deep learning models to provide accurate and efficient solutions for various computer vision tasks, such as object detection, image recognition, image segmentation, and video analysis. With our computer vision services, we help businesses unlock the potential of visual data and make data-driven decisions with confidence.</p>
                        <p>We specializes in delivering comprehensive computer vision services that enable businesses to leverage the power of visual data. Our team of computer vision experts utilizes advanced algorithms, machine learning, and artificial intelligence to extract valuable insights from images and videos, empowering organizations to make data-driven decisions.</p>
                        <p>we customize our computer vision services to meet the unique requirements of each client. We provide end-to-end solutions, including data preprocessing, model development, and deployment, ensuring seamless integration with existing systems or applications.</p>

<p>With our computer vision expertise, businesses can automate processes, gain valuable insights from visual data, improve decision-making, and unlock new opportunities for growth.</p>

                <div class="row">
                  
                   
                    
                    <div class="col-md-12 col-lg-6 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-yellow">
                                    <i class="flaticon-voice-recognition"></i>
                                </div>
                                <span>Object Detection</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>We employ state-of-the-art object detection techniques to locate and identify objects within images or videos. This enables applications such as automated surveillance, inventory management, and object tracking.</p>

                              
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-green">
                                    <i class="flaticon-machine-learning-1"></i>
                                </div>
                                <span>Image Recognition</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>Our deep learning models excel in image recognition, enabling accurate classification and recognition of objects, scenes, or patterns. This technology finds applications in fields such as autonomous vehicles, quality control, and medical imaging.</p>

                                <!-- <router-link to="/single-services" class="redirect-link">
                                    Discover More 
                                    <i class="icofont-rounded-right"></i>
                                </router-link> -->
                            </div>
                        </div>
                    </div>
                  
                  
                </div>
                <div class="row">
                    <div class="col-md-12 col-lg-6 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-blue">
                                    <i class="flaticon-machine-learning"></i>
                                </div>
                                <span>Image Segmentation</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>We utilize advanced segmentation algorithms to partition images into meaningful regions. This enables precise identification and analysis of specific objects or regions of interest, facilitating tasks such as medical image analysis, content-based image retrieval, and image editing.</p>

                               
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-6 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-violet">
                                    <i class="flaticon-processor"></i>
                                </div>
                                <span>Video Analysis</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>Our computer vision services extend to video analysis, where we leverage sophisticated algorithms to extract information from video streams. This includes tasks such as activity recognition, motion tracking, and event detection, enabling applications like video surveillance, sports analytics, and video content understanding.</p>

                              
                            </div>
                        </div>
                    </div>
                    
                  
                </div>

                <div class="row">
                    <div class="col-md-12 col-lg-6 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-yellow">
                                    <i class="flaticon-machine-learning-1"></i>
                                </div>
                                <span>Augmented Reality (AR) and Virtual Reality (VR)</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>We integrate computer vision technologies with AR and VR to create immersive experiences and interactive solutions. This includes marker-based and markerless tracking, object recognition, and real-time virtual object placement.</p>

                               
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-6 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-green">
                                    <i class="flaticon-voice-recognition"></i>
                                </div>
                                <span>Facial Recognition</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>Our facial recognition capabilities involve detecting and analyzing facial features to identify individuals, authenticate users, and enable personalized experiences. This technology finds applications in security systems, access control, and customer analytics.</p>

                              
                            </div>
                        </div>
                    </div>
                    
                  
                </div>
           

                        <h2>Our Process</h2>
                        <h4>1. Problem Definition and Requirements Gathering</h4>
                        <p> We begin by collaborating closely with our clients to understand their specific requirements and objectives. This involves defining the problem to be solved using computer vision techniques, identifying the desired outcomes, and establishing the project's constraints and scope.</p>

                        <h4>2. Data Acquisition and Preprocessing</h4>
                        <p> In this step, we acquire the relevant visual data necessary to address the defined problem. This may involve collecting images or videos from various sources, including client databases, public datasets, or specialized data acquisition techniques. We then preprocess the data by performing tasks such as resizing, normalization, noise reduction, and color space conversion. This ensures the data is in a suitable format for subsequent analysis.</p>

                        <h4>3. Feature Extraction and Representation</h4>
                        <p> We employ advanced feature extraction techniques to capture relevant information from the visual data. This step involves identifying and extracting distinctive features that represent the underlying patterns and characteristics of the images or videos. Techniques such as scale-invariant feature transform (SIFT), histogram of oriented gradients (HOG), or convolutional neural networks (CNNs) are used to extract robust and discriminative features.</p>

                        <h4>4. Model Selection and Training</h4>
                        <p> Once the features are extracted, we carefully select the appropriate computer vision model for the task at hand. This decision depends on factors such as the problem type (e.g., object detection, image classification, or semantic segmentation), available data, and desired performance metrics. We then train the selected model using labeled data, where the model learns the patterns and relationships between the extracted features and the corresponding labels. Training may involve optimization algorithms like stochastic gradient descent or transfer learning from pre-trained models.</p>

                        <h4>5. Model Evaluation and Validation</h4>
                        <p> To assess the performance of the trained model, we employ rigorous evaluation and validation techniques. We measure the model's accuracy, precision, recall, F1-score, or other relevant metrics, using appropriate evaluation methodologies such as cross-validation or hold-out validation. This step ensures that the model generalizes well and performs effectively on unseen data.</p>

                        <h4>6. Model Optimization and Fine-tuning</h4>
                        <p> We understand the importance of optimizing and fine-tuning computer vision models to achieve the best possible performance. This involves hyperparameter tuning, adjusting model parameters, and exploring different architecture designs. We leverage techniques like grid search, random search, or Bayesian optimization to systematically search for the optimal configuration that maximizes performance metrics.</p>

                        <h4>7. Deployment and Integration</h4>
                        <p>Once the computer vision model is trained, evaluated, and optimized, we proceed with deploying it into a production environment. This includes integrating the model into existing systems or applications, building user-friendly interfaces for seamless interaction, and ensuring scalability and performance requirements are met. We also consider the real-time aspects of the deployment, such as processing speed and resource utilization.</p>

                        <h4>8. Monitoring and Maintenance</h4>
                        <p>To ensure the ongoing effectiveness of computer vision systems, TechSolutions emphasizes the importance of monitoring and maintenance. We establish mechanisms to monitor model performance, detect anomalies or drift, and apply necessary updates or retraining to maintain accuracy and adapt to changing data or conditions.</p>

                        
                    </div>
                </div>
   
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesDetails'
}
</script>