import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import en from './locales/en.json';
import fr from './locales/fr.json';
import es from './locales/es.json';
import de from './locales/de.json';
import nl from './locales/nl.json';


import "./assets/custom.scss";
import { createI18n } from 'vue-i18n';

const i18n = createI18n({
    locale: 'en', // default language
    fallbackLocale: 'en', // fallback language
    messages: {
      en,
      fr,
      es,
      de,
      nl,
    },
  });

createApp(App).use(i18n).use(router).mount("#app");
