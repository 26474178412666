<template>
    <div class="process-section pt-100 pb-70 bg-off-white">
        <div class="container">
            <div class="section-title section-title-lg">
                <small>Our Process</small>
                <h2>Process How We Will Help Your Business</h2>
            </div>
            <div class="process-content">
                <div class="process-content-line"></div>
                <div class="row justify-content-center">
                    <div class="col-sm-6 col-lg-4 pb-30">
                        <div class="process-item process-item-center border">
                            <div class="process-thumb">
                                <img src="../../assets/images/process/process-one.png" alt="shape">
                            </div>
                            <div class="process-text">
                                <h3>Planning</h3>
                                <p>We leave no stone unturned. Through meticulous planning, invest time and expertise to thoroughly strategize, we lay the foundation for success, identifying goals and mapping out strategies that align with your vision.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 pb-30">
                        <div class="process-item process-item-center border">
                            <div class="process-thumb">
                                <img src="../../assets/images/process/process-two.png" alt="shape">
                            </div>
                            <div class="process-text">
                                <h3>Design & Execution</h3>
                                <p>With a focus on design and execution, we bring ideas to life, leveraging our expertise to create innovative solutions while ensuring seamless implementation for optimal results. From concept to reality, we are dedicated to delivering excellence.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 pb-30">
                        <div class="process-item process-item-center border">
                            <div class="process-thumb">
                                <img src="../../assets/images/process/process-three.png" alt="shape">
                            </div>
                            <div class="process-text">
                                <h3>Report & Signout</h3>
                                <p>Our commitment to transparency and accountability shines through our reporting and sign-off process, providing comprehensive insights and seeking your approval, ensuring your satisfaction every step of the way.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Process'
}
</script>