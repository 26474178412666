<template>
    <div class="service-section pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 pb-30">
                    <!-- <div class="sidebar-item sidebar-bg details-sidebar-search">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Search">
                            <button type="submit">
                                <i class="flaticon-search"></i>
                            </button>
                        </div>
                    </div> -->
                    <div class="sidebar-item sidebar-bg">
                        <h3>Services</h3>
                        <ul class="sidebar-list">
                            <li>
                                <router-link to="/machine-learning" >Machine Learning</router-link>
                            </li>
                            <li>
                                <router-link to="/computer-vision" >Computer Vision</router-link>
                            </li>
                            <li>
                                <router-link to="/data-analytics">Data Analytics</router-link>
                            </li>
                            <li>
                                <router-link to="/iot">IoT</router-link>
                            </li>
                            <li>
                                <router-link to="/mobile-web-development"  >Mobile App and Website Development</router-link>
                            </li>
                            <li>
                                <router-link to="/erp" >Enterprise Resource Planning (ERP)</router-link>
                            </li>
                            <li>
                                <router-link to="/accounting-software" class="current">Accounting Software</router-link>
                            </li>
                            <li>
                                <router-link to="/pos">Point of Sale (POS) Solutions</router-link>
                            </li>
                           
                        </ul>
                    </div>
                    <!-- <div class="sidebar-item sidebar-bg bg-blue-dark">
                        <div class="sidebar-author">
                            <p>“Proin gravida nibh vel velit auctor aliquet nean sollicitudin.Proin gravida nibh vel velit auctor aliquet Aenean sollicitudin.Proin vel velit auctor aliquet Aenean sollicitudin.”</p>
                            <div class="author-info">
                                <img src="../../assets/images/author-1.jpg" alt="author">
                                <div class="author-info-details">
                                    <h3>Jonson Mandel</h3>
                                    <p>Los Angel</p>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="sidebar-item">
                        <h3>Recent Post</h3>
                        <div class="sidebar-recent-post">
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-4.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>Is Machine Learning Right Going On Good Way</h3>
                                        <ul class="post-entry">
                                            <li>January 25, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-5.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>The Current State Of Artificial Intelligence Infographic</h3>
                                        <ul class="post-entry">
                                            <li>January 24, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-6.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>Our Company As A Global Leader In Big Data</h3>
                                        <ul class="post-entry">
                                            <li>January 23, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="col-lg-8 pb-30">
                    <div class="forum-details desk-pad-right-40">
                        <img src="../../assets/images/services/accounting.jpg" alt="service">
                        <h2>Accounting Software</h2>
                        <p>Our accounting software solutions provide businesses with a powerful and efficient way to manage their financial processes, streamline bookkeeping tasks, and gain valuable insights into their financial health. Designed to simplify and automate accounting operations, our software is tailored to meet the specific needs of businesses across various industries.</p>
                        <!-- <h4>Native App Development</h4>
                        <p>We excel in developing native apps using platform-specific technologies such as Swift and Objective-C for iOS apps and Java or Kotlin for Android apps. Native apps offer the best performance, device integration, and access to the latest platform features.</p>
                       -->

                        <div class="row">
                        
                        
                            
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-yellow">
                                            <i class="flaticon-voice-recognition"></i>
                                        </div>
                                        <span>Financial Management</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>Our accounting software offers comprehensive financial management functionalities, including general ledger, accounts payable, accounts receivable, and bank reconciliation. It helps businesses accurately track income, expenses, and cash flow, ensuring compliance with accounting standards and facilitating timely financial reporting.</p>

                                    
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-green">
                                            <i class="flaticon-machine-learning-1"></i>
                                        </div>
                                        <span>Invoicing and Billing</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>Simplify your invoicing and billing processes with our software. Create professional invoices, automate recurring billing, and easily manage customer payments. With customizable templates and automated reminders, you can efficiently track and collect payments, improving cash flow management.</p>

                                        <!-- <router-link to="/single-services" class="redirect-link">
                                            Discover More 
                                            <i class="icofont-rounded-right"></i>
                                        </router-link> -->
                                    </div>
                                </div>
                            </div>
                        
                        
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-blue">
                                            <i class="flaticon-machine-learning"></i>
                                        </div>
                                        <span>Expense Tracking</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>Keep track of expenses efficiently with our software's expense tracking features. Capture and categorize expenses, upload receipts, and monitor spending in real-time. This enables businesses to accurately analyze expenses, identify cost-saving opportunities, and streamline reimbursement processes.</p>

                                    
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12 col-lg-6 pb-30">
                                

                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-blue">
                                            <i class="flaticon-machine-learning"></i>
                                        </div>
                                        <span>Tax Compliance</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>Simplify tax management and compliance. Our accounting software helps you stay up to date with tax regulations by automatically calculating taxes, generating tax reports, and providing a clear audit trail. This ensures accurate tax filing and minimizes the risk of penalties or errors.</p>

                                    
                                    </div>
                                </div>
                                
                            </div>
                            
                        
                        </div>

                        <div class="row">
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-violet">
                                            <i class="flaticon-processor"></i>
                                        </div>
                                        <span>Financial Reporting and Analysis</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>Generate insightful financial reports with ease. Our software provides a variety of standard financial reports such as balance sheets, income statements, cash flow statements, and customizable reports tailored to your business needs. These reports enable you to make data-driven decisions, identify trends, and monitor key performance indicators.</p>

                                    
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-yellow">
                                            <i class="flaticon-machine-learning-1"></i>
                                        </div>
                                        <span>Integration with Third-Party Systems</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>Our accounting software seamlessly integrates with your banking institutions, allowing for automatic bank feeds, transaction synchronization, and easy reconciliation. Additionally, it can integrate with other business systems such as customer relationship management (CRM) software or inventory management systems, ensuring data consistency and reducing manual data entry.</p>

                                    
                                    </div>
                                </div>
                            </div>

                        
                        
                        </div>

                        <div class="row">
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-yellow">
                                            <i class="flaticon-machine-learning-1"></i>
                                        </div>
                                        <span>Data Security and Accessibility</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>Our software prioritizes the security of your financial data. It implements robust encryption, user access controls, and regular data backups to protect sensitive information. Additionally, our cloud-based solutions offer the convenience of accessing your accounting data anytime, anywhere, from any device with an internet connection.</p>

                                    
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6 pb-30">
                                <div class="feature-card">
                                    <div class="feature-card-header">
                                        <div class="feature-card-thumb feature-card-thumb-blue">
                                            <i class="flaticon-machine-learning"></i>
                                        </div>
                                        <span>Scalability and Customization</span>
                                    </div>
                                    <div class="feature-card-body"> 
                                        <p>Our accounting software solutions are scalable and adaptable to your business growth. Whether you are a small business or a large enterprise, our software can accommodate your evolving needs. We also offer customization options to tailor the software to your specific industry requirements and workflows.</p>

                                    
                                    </div>
                                </div>
                            </div>

                        
                        
                        </div>


                        

                    </div>
                </div>
   
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesDetails'
}
</script>