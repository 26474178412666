<template>
    <div class="blog-section pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 pb-30">
                    <div class="row desk-pad-right-40">
                        <div class="col-md-6 pb-30">
                            <div class="blog-card">
                                <div class="blog-card-image">
                                    <router-link to="/single-blog">
                                        <img src="../../assets/images/blogs/blog-1.jpg" alt="blog">
                                    </router-link>
                                </div>
                                <div class="blog-card-text">
                                    <div class="blog-category">Technology</div>
                                    <h3>
                                        <router-link to="/single-blog">Is Machine Learning Right Going On A Good Way</router-link>
                                    </h3>
                                    <div class="blog-card-entry">
                                        <div class="blog-entry-thumb">
                                            <img src="../../assets/images/blogs/blog-author-1.jpg" alt="author">
                                        </div>
                                        <div class="blog-entry-text">
                                            <h4>By: <strong>David Joe</strong></h4>
                                            <p>25 January 2021</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 pb-30">
                            <div class="blog-card">
                                <div class="blog-card-image">
                                    <router-link to="/single-blog">
                                        <img src="../../assets/images/blogs/blog-2.jpg" alt="blog">
                                    </router-link>
                                </div>
                                <div class="blog-card-text">
                                    <div class="blog-category">Robot</div>
                                    <h3>
                                        <router-link to="/single-blog">The Current State Of Artificial Intelligence Infographic</router-link>
                                    </h3>
                                    <div class="blog-card-entry">
                                        <div class="blog-entry-thumb">
                                            <img src="../../assets/images/blogs/blog-author-2.jpg" alt="author">
                                        </div>
                                        <div class="blog-entry-text">
                                            <h4>By: <strong>Lona Rabisa</strong></h4>
                                            <p>24 January 2021</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 pb-30">
                            <div class="blog-card">
                                <div class="blog-card-image">
                                    <router-link to="/single-blog">
                                        <img src="../../assets/images/blogs/blog-3.jpg" alt="blog">
                                    </router-link>
                                </div>
                                <div class="blog-card-text">
                                    <div class="blog-category">Machine</div>
                                    <h3>
                                        <router-link to="/single-blog">Our Company As A Global Leader In Big Data</router-link>
                                    </h3>
                                    <div class="blog-card-entry">
                                        <div class="blog-entry-thumb">
                                            <img src="../../assets/images/blogs/blog-author-3.jpg" alt="author">
                                        </div>
                                        <div class="blog-entry-text">
                                            <h4>By: <strong>Richard Jac</strong></h4>
                                            <p>23 January 2021</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 pb-30">
                            <div class="blog-card">
                                <div class="blog-card-image">
                                    <router-link to="/single-blog">
                                        <img src="../../assets/images/blogs/blog-7.jpg" alt="blog">
                                    </router-link>
                                </div>
                                <div class="blog-card-text">
                                    <div class="blog-category">Machine</div>
                                    <h3>
                                        <router-link to="/single-blog">New Ml Technology Used For New Drone</router-link>
                                    </h3>
                                    <div class="blog-card-entry">
                                        <div class="blog-entry-thumb">
                                            <img src="../../assets/images/blogs/blog-author-2.jpg" alt="author">
                                        </div>
                                        <div class="blog-entry-text">
                                            <h4>By: <strong>Lona Rabisa</strong></h4>
                                            <p>22 January 2021</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 pb-30">
                            <div class="blog-card">
                                <div class="blog-card-image">
                                    <router-link to="/single-blog">
                                        <img src="../../assets/images/blogs/blog-8.jpg" alt="blog">
                                    </router-link>
                                </div>
                                <div class="blog-card-text">
                                    <div class="blog-category">Machine</div>
                                    <h3>
                                        <router-link to="/single-blog">Machine Learning Are Greatly Used For Data Mining</router-link>
                                    </h3>
                                    <div class="blog-card-entry">
                                        <div class="blog-entry-thumb">
                                            <img src="../../assets/images/blogs/blog-author-3.jpg" alt="author">
                                        </div>
                                        <div class="blog-entry-text">
                                            <h4>By: <strong>Richard Jac</strong></h4>
                                            <p>21 January 2021</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 pb-30">
                            <div class="blog-card">
                                <div class="blog-card-image">
                                    <router-link to="/single-blog">
                                        <img src="../../assets/images/blogs/blog-9.jpg" alt="blog">
                                    </router-link>
                                </div>
                                <div class="blog-card-text">
                                    <div class="blog-category">Machine</div>
                                    <h3>
                                        <router-link to="/single-blog">Robot Exhibition In New Central Park Zone</router-link>
                                    </h3>
                                    <div class="blog-card-entry">
                                        <div class="blog-entry-thumb">
                                            <img src="../../assets/images/blogs/blog-author-1.jpg" alt="author">
                                        </div>
                                        <div class="blog-entry-text">
                                            <h4>By: <strong>David Joe</strong></h4>
                                            <p>20 January 2021</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 pb-30">
                            <div class="blog-card">
                                <div class="blog-card-image">
                                    <router-link to="/single-blog">
                                        <img src="../../assets/images/blogs/blog-10.jpg" alt="blog">
                                    </router-link>
                                </div>
                                <div class="blog-card-text">
                                    <div class="blog-category">Technology</div>
                                    <h3>
                                        <router-link to="/single-blog">Machine Learning Helping In Storing Data</router-link>
                                    </h3>
                                    <div class="blog-card-entry">
                                        <div class="blog-entry-thumb">
                                            <img src="../../assets/images/blogs/blog-author-1.jpg" alt="author">
                                        </div>
                                        <div class="blog-entry-text">
                                            <h4>By: <strong>David Joe</strong></h4>
                                            <p>19 January 2021</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 pb-30">
                            <div class="blog-card">
                                <div class="blog-card-image">
                                    <router-link to="/single-blog">
                                        <img src="../../assets/images/blogs/blog-11.jpg" alt="blog">
                                    </router-link>
                                </div>
                                <div class="blog-card-text">
                                    <div class="blog-category">Robot</div>
                                    <h3>
                                        <router-link to="/single-blog">Ml Is Using In Every Sector Of Modern Technology</router-link>
                                    </h3>
                                    <div class="blog-card-entry">
                                        <div class="blog-entry-thumb">
                                            <img src="../../assets/images/blogs/blog-author-2.jpg" alt="author">
                                        </div>
                                        <div class="blog-entry-text">
                                            <h4>By: <strong>Lona Rabisa</strong></h4>
                                            <p>18 January 2021</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav class="page-pagination" aria-label="Page Pagination">
                        <ul class="pagination">
                            <li class="page-item disabled">
                                <a class="page-link" href="blogs" tabindex="-1" aria-disabled="true">
                                    <i class="flaticon-left-chevron"></i>
                                </a>
                            </li>
                            <li class="page-item active">
                                <a class="page-link" href="blogs">1</a>
                            </li>
                            <li class="page-item" aria-current="page">
                                <a class="page-link" href="blogs">2</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="blogs">3</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="blogs">...</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="blogs">10</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="blogs">
                                    <i class="flaticon-right-chevron"></i>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="col-lg-4 pb-30">
                    <Sidebar />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from '../BlogRightSidebar/Sidebar'

export default {
    name: 'Blog',
    components: {
        Sidebar
    }
}
</script>