<template>
    <div class="service-section pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 pb-30">
                    <!-- <div class="sidebar-item sidebar-bg details-sidebar-search">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Search">
                            <button type="submit">
                                <i class="flaticon-search"></i>
                            </button>
                        </div>
                    </div> -->
                    <div class="sidebar-item sidebar-bg">
                        <h3>Services</h3>
                        <ul class="sidebar-list">
                            <li>
                                <router-link to="/machine-learning" >Machine Learning</router-link>
                            </li>
                            <li>
                                <router-link to="/computer-vision" >Computer Vision</router-link>
                            </li>
                            <li>
                                <router-link to="/data-analytics">Data Analytics</router-link>
                            </li>
                            <li>
                                <router-link to="/iot"  class="current" >IoT</router-link>
                            </li>
                            <li>
                                <router-link to="/mobile-web-development">Mobile App and Website Development</router-link>
                            </li>
                            <li>
                                <router-link to="/erp">Enterprise Resource Planning (ERP)</router-link>
                            </li>
                            <li>
                                <router-link to="/accounting-software">Accounting Software</router-link>
                            </li>
                            <li>
                                <router-link to="/pos">Point of Sale (POS) Solutions</router-link>
                            </li>
                           
                        </ul>
                    </div>
                    <!-- <div class="sidebar-item sidebar-bg bg-blue-dark">
                        <div class="sidebar-author">
                            <p>“Proin gravida nibh vel velit auctor aliquet nean sollicitudin.Proin gravida nibh vel velit auctor aliquet Aenean sollicitudin.Proin vel velit auctor aliquet Aenean sollicitudin.”</p>
                            <div class="author-info">
                                <img src="../../assets/images/author-1.jpg" alt="author">
                                <div class="author-info-details">
                                    <h3>Jonson Mandel</h3>
                                    <p>Los Angel</p>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="sidebar-item">
                        <h3>Recent Post</h3>
                        <div class="sidebar-recent-post">
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-4.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>Is Machine Learning Right Going On Good Way</h3>
                                        <ul class="post-entry">
                                            <li>January 25, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-5.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>The Current State Of Artificial Intelligence Infographic</h3>
                                        <ul class="post-entry">
                                            <li>January 24, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-6.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>Our Company As A Global Leader In Big Data</h3>
                                        <ul class="post-entry">
                                            <li>January 23, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="col-lg-8 pb-30">
                    <div class="forum-details desk-pad-right-40">
                        <img src="../../assets/images/services/iot.jpg" alt="service">
                        <h2>Internet of Things</h2>
                        <p>we specialize in offering comprehensive Internet of Things (IoT) solutions that enable businesses to connect, monitor, and control devices and systems for enhanced efficiency and productivity. With our expertise in IoT technologies, we help organizations harness the power of connected devices, gather valuable data, and unlock new opportunities for innovation and growth.</p>

                        <h2>IoT Solutions</h2>
                       
                        <p> Our IoT solutions encompass a wide range of industries and applications, including smart cities, healthcare, manufacturing, agriculture, logistics, and more. We design and develop customized IoT solutions that cater to your specific business needs, empowering you to optimize operations, automate processes, and improve decision-making.</p>

                        <h4>Device Connectivity and Integration</h4>
                        <p> We facilitate seamless device connectivity by integrating sensors, actuators, and various IoT-enabled devices. Our team ensures secure and reliable communication protocols to establish a robust IoT network infrastructure. We have expertise in technologies such as MQTT, CoAP, LoRaWAN, Zigbee, and cellular connectivity (2G, 3G, 4G, and emerging 5G).</p>

                        <h4>Data Collection and Analytics</h4>
                        <p> We enable businesses to collect and analyze vast amounts of data generated by IoT devices. Our data analytics solutions leverage advanced techniques such as machine learning and artificial intelligence to derive meaningful insights from sensor data. This empowers organizations to make data-driven decisions, predict trends, optimize processes, and enhance overall operational efficiency.</p>

                        <h4>Cloud Platform and Integration</h4>
                        <p> We utilize cloud platforms, such as Amazon Web Services (AWS), Microsoft Azure, or Google Cloud Platform, to securely store and process IoT data. Cloud integration allows for scalability, real-time data access, remote device management, and seamless integration with other enterprise systems. We ensure data privacy and implement robust security measures to protect sensitive information.</p>

                        <h4>Real-time Monitoring and Control</h4>
                        <p> Our IoT solutions enable real-time monitoring and control of connected devices and systems. Through intuitive dashboards and mobile applications, businesses can remotely monitor and manage critical parameters, receive alerts, and automate actions based on predefined rules. This leads to improved operational efficiency, reduced downtime, and proactive maintenance.</p>

                        <h4>Security and Privacy</h4>
                        <p>We prioritize the security and privacy of IoT deployments. We implement robust security protocols and encryption mechanisms to protect data transmission and prevent unauthorized access. Our experts conduct comprehensive security assessments to identify vulnerabilities and ensure compliance with industry standards and regulations.</p>

                        <h4>Scalability and Future-readiness:</h4>
                        <p>We rigorously evaluate and validate the trained models to assess their performance and generalization capabilities. We employ a range of evaluation metrics, such as accuracy, precision, recall, or root mean squared error, depending on the project requirements. We ensure robust validation through techniques like cross-validation and hold-out validation.</p>

                        <h4>Consulting and Strategy</h4>
                        <p>In addition to development and implementation, we offer consulting and strategy services to help businesses define their IoT roadmap. Our experts collaborate closely with clients to understand their goals and challenges, assess feasibility, and develop a comprehensive IoT strategy that aligns with their business objectives.</p>

                      <p>Partner with us to leverage the transformative potential of IoT and drive innovation in your industry. Our end-to-end IoT solutions, combined with our expertise in technology and industry best practices, will empower your business to thrive in the interconnected world of tomorrow.</p>
                    </div>
                </div>
   
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesDetails'
}
</script>