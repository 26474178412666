<template>
    <div class="faq-section pt-100 pb-70">
        <div class="container">
            <accordion>
                <div class="row">
                    <div class="col-lg-6 pb-30">
                        <accordion-item>
                            <template v-slot:accordion-trigger>
                                <button class="accordion-title">
                                    How To Successful Our Business Plan?
                                </button>
                            </template>
                            <template v-slot:accordion-content>
                                <div class="accordion-body">
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tutip tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At veropi eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea tak imata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, conse tetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.
                                </div>
                            </template>
                        </accordion-item>
                        <accordion-item>
                            <template v-slot:accordion-trigger>
                                <button class="accordion-title">
                                    Clients Satisfaction Make Company Value
                                </button>
                            </template>
                            <template v-slot:accordion-content>
                                <div class="accordion-body">
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tutip tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At veropi eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea tak imata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, conse tetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.
                                </div>
                            </template>
                        </accordion-item>
                        <accordion-item>
                            <template v-slot:accordion-trigger>
                                <button class="accordion-title">
                                    World Class Creative Firm
                                </button>
                            </template>
                            <template v-slot:accordion-content>
                                <div class="accordion-body">
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tutip tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At veropi eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea tak imata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, conse tetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.
                                </div>
                            </template>
                        </accordion-item>
                        <accordion-item>
                            <template v-slot:accordion-trigger>
                                <button class="accordion-title">
                                    What Are The Business Advisory Company?
                                </button>
                            </template>
                            <template v-slot:accordion-content>
                                <div class="accordion-body">
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tutip tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At veropi eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea tak imata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, conse tetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.
                                </div>
                            </template>
                        </accordion-item>
                    </div>
                    <div class="col-lg-6 pb-30">
                        <accordion-item>
                            <template v-slot:accordion-trigger>
                                <button class="accordion-title">
                                    Research Is What Makes An Effective Business Plan?
                                </button>
                            </template>
                            <template v-slot:accordion-content>
                                <div class="accordion-body">
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tutip tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At veropi eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea tak imata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, conse tetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.
                                </div>
                            </template>
                        </accordion-item>
                        <accordion-item>
                            <template v-slot:accordion-trigger>
                                <button class="accordion-title">
                                    How Achieving Small Business Success?
                                </button>
                            </template>
                            <template v-slot:accordion-content>
                                <div class="accordion-body">
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tutip tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At veropi eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea tak imata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, conse tetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.
                                </div>
                            </template>
                        </accordion-item>
                        <accordion-item>
                            <template v-slot:accordion-trigger>
                                <button class="accordion-title">
                                    Why Business Planing Is Important?
                                </button>
                            </template>
                            <template v-slot:accordion-content>
                                <div class="accordion-body">
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tutip tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At veropi eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea tak imata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, conse tetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.
                                </div>
                            </template>
                        </accordion-item>
                        <accordion-item>
                            <template v-slot:accordion-trigger>
                                <button class="accordion-title">
                                    How Do You Startup?
                                </button>
                            </template>
                            <template v-slot:accordion-content>
                                <div class="accordion-body">
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tutip tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At veropi eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea tak imata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, conse tetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.
                                </div>
                            </template>
                        </accordion-item>
                    </div>
                </div>
            </accordion>
        </div>
    </div>
</template>

<script>
import Accordion from "../Common/Accordion";
import AccordionItem from "../Common/AccordionItem";

export default {
    name: 'Faq',
    components: {
        Accordion,
        AccordionItem,
    },
}
</script>