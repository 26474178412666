<template>
    <div class="service-section pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 pb-30">
                    <!-- <div class="sidebar-item sidebar-bg details-sidebar-search">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Search">
                            <button type="submit">
                                <i class="flaticon-search"></i>
                            </button>
                        </div>
                    </div> -->
                    <div class="sidebar-item sidebar-bg">
                        <h3>Services</h3>
                        <ul class="sidebar-list">
                            <li>
                                <router-link to="/machine-learning" >Machine Learning</router-link>
                            </li>
                            <li>
                                <router-link to="/computer-vision" >Computer Vision</router-link>
                            </li>
                            <li>
                                <router-link to="/data-analytics" class="current">Data Analytics</router-link>
                            </li>
                            <li>
                                <router-link to="/iot">IoT</router-link>
                            </li>
                            <li>
                                <router-link to="/mobile-web-development">Mobile App and Website Development</router-link>
                            </li>
                            <li>
                                <router-link to="/erp">Enterprise Resource Planning (ERP)</router-link>
                            </li>
                            <li>
                                <router-link to="/accounting-software">Accounting Software</router-link>
                            </li>
                            <li>
                                <router-link to="/pos">Point of Sale (POS) Solutions</router-link>
                            </li>
                           
                        </ul>
                    </div>
                    <!-- <div class="sidebar-item sidebar-bg bg-blue-dark">
                        <div class="sidebar-author">
                            <p>“Proin gravida nibh vel velit auctor aliquet nean sollicitudin.Proin gravida nibh vel velit auctor aliquet Aenean sollicitudin.Proin vel velit auctor aliquet Aenean sollicitudin.”</p>
                            <div class="author-info">
                                <img src="../../assets/images/author-1.jpg" alt="author">
                                <div class="author-info-details">
                                    <h3>Jonson Mandel</h3>
                                    <p>Los Angel</p>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="sidebar-item">
                        <h3>Recent Post</h3>
                        <div class="sidebar-recent-post">
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-4.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>Is Machine Learning Right Going On Good Way</h3>
                                        <ul class="post-entry">
                                            <li>January 25, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-5.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>The Current State Of Artificial Intelligence Infographic</h3>
                                        <ul class="post-entry">
                                            <li>January 24, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                            <div class="sidebar-recent-post-item">
                                <router-link to="/single-services">
                                    <img src="../../assets/images/services/service-6.png" alt="service">
                                    <div class="recent-post-details">
                                        <h3>Our Company As A Global Leader In Big Data</h3>
                                        <ul class="post-entry">
                                            <li>January 23, 2021</li>
                                        </ul>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="col-lg-8 pb-30">
                    <div class="forum-details desk-pad-right-40">
                        <img src="../../assets/images/services/service-details.jpg" alt="service">
                        <h2>Data Analytics</h2>
                        <p>We offers comprehensive data analytics services to help businesses harness the power of their data. With advanced techniques and cutting-edge tools, our team of data experts transforms raw data into actionable insights. From data exploration and visualization to predictive modeling and decision support, we provide tailored solutions that drive informed strategies and optimize business performance.</p>
                        <p>We specializes in providing end-to-end data analytics services, empowering businesses to unlock the value hidden within their data. Our team of skilled data scientists and analysts combine expertise in statistical analysis, machine learning, and data visualization to deliver actionable insights and drive data-informed decision-making.</p>
                      

                <div class="row">
                  
                   
                    
                    <div class="col-md-12 col-lg-6 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-yellow">
                                    <i class="flaticon-voice-recognition"></i>
                                </div>
                                <span>Data Exploration and Visualization</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>We start by exploring and understanding your data, analyzing its characteristics and patterns. Our experts employ various statistical techniques, data mining, and visualization tools to uncover valuable insights. Through intuitive visualizations, we present the data in a clear and meaningful manner, enabling stakeholders to grasp key trends and make informed decisions.</p>

                              
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-green">
                                    <i class="flaticon-machine-learning-1"></i>
                                </div>
                                <span>Descriptive Analytics</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>Using historical data, we perform descriptive analytics to understand past trends and patterns. We employ statistical analysis techniques to summarize data, measure central tendencies, identify correlations, and detect outliers. This helps businesses gain a comprehensive understanding of their operations, customer behavior, and market dynamics.</p>

                                <!-- <router-link to="/single-services" class="redirect-link">
                                    Discover More 
                                    <i class="icofont-rounded-right"></i>
                                </router-link> -->
                            </div>
                        </div>
                    </div>
                  
                  
                </div>
                <div class="row">
                    <div class="col-md-12 col-lg-6 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-blue">
                                    <i class="flaticon-machine-learning"></i>
                                </div>
                                <span>Predictive Analytics</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>Our predictive analytics services leverage machine learning algorithms to forecast future outcomes and behavior. We develop predictive models to identify patterns and relationships within the data, enabling accurate predictions and scenario analysis. This empowers businesses to make proactive decisions, optimize processes, and mitigate risks.</p>

                               
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-6 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-violet">
                                    <i class="flaticon-processor"></i>
                                </div>
                                <span>Prescriptive Analytics</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>In addition to predicting outcomes, our prescriptive analytics services go a step further by recommending optimal actions. Through optimization algorithms and decision support systems, we help businesses identify the best course of action based on various constraints and objectives. This enables data-driven decision-making and resource optimization.</p>

                              
                            </div>
                        </div>
                    </div>
                    
                  
                </div>

                <div class="row">
                    <div class="col-md-12 col-lg-6 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-yellow">
                                    <i class="flaticon-machine-learning-1"></i>
                                </div>
                                <span>Customer Analytics</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>Understanding customer behavior is crucial for businesses to drive growth and enhance customer experience. Our customer analytics services leverage data to uncover insights into customer preferences, segmentation, lifetime value, and churn prediction. This enables personalized marketing strategies, targeted campaigns, and effective customer retention efforts.</p>

                               
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-6 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-green">
                                    <i class="flaticon-voice-recognition"></i>
                                </div>
                                <span>Data-driven Strategy and Performance Optimization</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>TechSolutions assists businesses in leveraging data to formulate effective strategies and optimize performance. By analyzing key performance indicators (KPIs), conducting market research, and performing competitive analysis, we provide actionable recommendations that drive business growth and efficiency.</p>
                            </div>
                        </div>
                    </div>
                    
                  
                </div>
           

                        <h2>Our Process</h2>
                        <h4>1. Define Objectives and Problem Statement</h4>
                        <p> Begin by clearly defining the objectives of the data analytics project and the specific problem you aim to solve. Identify the key questions you want to answer or the challenges you want to address through data analysis.</p>

                        <h4>2. Data Collection and Integration</h4>
                        <p> Our team collects data from diverse sources, including client databases, third-party APIs, and public datasets. We ensure data privacy and security throughout the collection process. We integrate and consolidate the data using robust data management practices, ensuring data consistency and accuracy.</p>

                        <h4>3. Data Cleaning and Preprocessing</h4>
                        <p> We employ advanced data cleaning and preprocessing techniques to handle missing values, outliers, and inconsistencies. We perform data validation and normalization to ensure the data is in a suitable format for analysis. Our experts use domain knowledge and automated tools to streamline this process efficiently.</p>

                        <h4>4. Exploratory Data Analysis (EDA)</h4>
                        <p> We conduct comprehensive exploratory data analysis to gain insights into the data. Through data visualization, statistical summaries, and advanced analytics, we uncover patterns, relationships, and potential issues. This helps us understand the data's characteristics, identify key variables, and generate hypotheses for further analysis.</p>

                        <h4>5.Data Transformation and Feature Engineering</h4>
                        <p> Our data scientists apply data transformation techniques to enhance the quality and predictive power of the data. We perform feature engineering by creating new variables, encoding categorical data, and applying mathematical transformations. This step aims to improve the performance of the models and extract valuable insights.</p>

                        <h4>6. Model Selection and Training</h4>
                        <p> Based on the problem statement and data characteristics, we select appropriate models from a range of options, including regression, classification, clustering, or predictive analytics algorithms. We split the data into training and testing sets and train the chosen models using state-of-the-art machine learning techniques and algorithms.</p>

                        <h4>7. Model Evaluation and Validation</h4>
                        <p>We rigorously evaluate and validate the trained models to assess their performance and generalization capabilities. We employ a range of evaluation metrics, such as accuracy, precision, recall, or root mean squared error, depending on the project requirements. We ensure robust validation through techniques like cross-validation and hold-out validation.</p>

                        <h4>8. Insights and Interpretation</h4>
                        <p>Our team of experts analyzes the results, extracts meaningful insights, and interprets the findings. We provide clear explanations and actionable recommendations based on the analysis, aligning them with the initial objectives. We emphasize delivering insights that can drive impactful decisions and improve business outcomes.</p>

                        <h4>9. Communication and Visualization</h4>
                        <p>We leverage effective data visualization techniques to communicate the findings to stakeholders. Our team creates visually appealing and intuitive dashboards, reports, and presentations that convey complex information in a simplified manner. We ensure clear and concise communication of insights to facilitate informed decision-making.</p>

                        <h4>10. Continuous Improvement and Iteration</h4>
                        <p>We embrace a culture of continuous improvement in our data analytics process. We seek feedback from stakeholders, monitor model performance, and explore opportunities for refinement. We update the analysis as new data becomes available and iterate on the models to ensure they remain relevant and effective.</p>
                        
                    </div>
                </div>
   
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesDetails'
}
</script>